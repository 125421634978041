<template>
  <div style="width: 100%; margin-right: auto; margin-left: auto">
    <BaseHeader title="Gestão de Leads" :navigation="dynamicNavigation">
      <!-- HIDDEN-FEATURE -->
      <!-- <div
        style="
          display: flex;
          justify-content: end;
          margin-right: 5px;
          cursor: pointer;
        "
      >
        <img
          src="@/assets/icons/companie.svg"
          alt="icon"
          v-b-tooltip.hover.bottom
          @click="changeCompanylead = !changeCompanylead"
          v-if="!changeCompanylead"
          title="Visualizar empresas"
        />
        <img
          src="@/assets/icons/lead-view.svg"
          alt="icon"
          @click="changeCompanylead = !changeCompanylead"
          v-if="changeCompanylead"
          v-b-tooltip.hover.bottom
          title="Visualizar leads"
        />
      </div> -->
    </BaseHeader>
    <!-- LEAD LISTA -->
    <div class="container-table" v-if="changeCompanylead == false">
      <div class="box-total">
        <div class="total-vendas info">
          <b-form-checkbox
            v-model="status"
            @change="fetchLeads"
            name="is_main"
            size="lg"
            switch
          >
            <p class="info-checkbox" style="width: 0px">
              {{ status ? " Ativos " : "Inativos" }}
            </p>
          </b-form-checkbox>
        </div>
        <div>
          <p class="total-vendas" >
            {{isMobile ? "Leads:" : "Total de leads:" }}
            <span v-if="$store.getters.recursos.nleads">{{filterTotalAndResource}} </span>
            <span v-else>{{ filterTotalAndResource}} </span>
          </p>
        </div>

        <div></div>
      </div>

      <p
        style="
          margin: 0;
          font-size: 12px;
          position: relative;
          top: 10px;
          color: var(--gray05);
          margin-bottom: 5px;
          margin-top: 5px;
        "
      >
        <svg
          @click="fetchLeads"
          v-b-tooltip="{
            title: 'Recarregar',
            placement: 'top',
          }"
          style="cursor: pointer"
          class="mr-2"
          width="15"
          height="15"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23 4V10H17"
            stroke="#8c8a97"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20.49 15C19.84 16.8399 18.6096 18.4187 16.9842 19.4985C15.3588 20.5783 13.4265 21.1006 11.4784 20.9866C9.53038 20.8726 7.67216 20.1286 6.18376 18.8667C4.69536 17.6047 3.65743 15.8932 3.22637 13.9901C2.79531 12.0869 2.99448 10.0952 3.79386 8.31508C4.59325 6.53496 5.94954 5.06288 7.65836 4.12065C9.36717 3.17843 11.3359 2.81711 13.268 3.09116C15.2 3.3652 16.9906 4.25975 18.37 5.64001L23 10"
            stroke="#8c8a97"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        Última atualização: {{ time_get }}
      </p>

      <!-- ACOES -->
      <div class="header-table" v-if="!isMobile">
        <div class="flex-sale">
          <div class="d-flex align-items-center">
            <b-tooltip
              target="filtro-1"
              title="Aplicar filtro"
              placement="topright"
            />
            <b-button
              id="filtro-1"
              class="btn-table"
              @click="openModal('modal-filter')"
              title="Filtros"
              >
              <img src="@/assets/img/icons/filtro.svg"/>
            </b-button>

            <b-tooltip
              target="add_lead"
              title="Adicionar lead"
              placement="topright"
            />
            <b-tooltip
              target="add_lead"
              title="Adicionar lead"
              placement="topright"
              v-if="reachedLimit"
            />
            <b-button
              v-else
              @click="openModal('add-lead')"
              class="btn-table"
              title="Adicionar lead"
              id="add_lead"
              ><img src="@/assets/img/icons/add_lead.svg"
            /></b-button>
            <b-button
              @click="leadLimit()"
              class="btn-table"
              title="Adicionar lead"
              id="add_lead"
              v-if="reachedLimit"
              ><img src="@/assets/img/icons/add_lead.svg"
            /></b-button>

            <b-tooltip target="actions-1" title="Ações" placement="topright" />

            <b-dropdown
              id="extras-btns"
              dropright
              v-if="!reachedLimit"
            >
              <template #button-content>
                <b-button id="actions-1" class="btn-table" title="Ações"
                  ><img src="@/assets/img/icons/dots_greenn.svg"
                /></b-button>
              </template>
              <b-dropdown-item @click="openModal('wpp-lead')" v-if="status"
                >Enviar mensagem WhatsApp</b-dropdown-item
              >
              <b-dropdown-item @click="openModal('email-unico')" v-if="status"
                >Enviar um e-mail</b-dropdown-item
              >
              <b-dropdown-item @click="openModal('gerence-tag')" v-if="status"
                >Adicionar / remover tag</b-dropdown-item
              >
              <!-- HIDDEN-FEATURE -->
              <b-dropdown-item
                @click="openModal('gerence-company')"
                v-if="status && false"
                >Adicionar / remover empresa</b-dropdown-item
              >
              <b-dropdown-item
                @click="openModal('seller-automation')"
                v-if="level === 'seller' ? this.config_seller_can_transfer_lead ? this.config_seller_can_transfer_lead == 'true' : true : true && status">
                  Automação vendedor
              </b-dropdown-item>
              <b-dropdown-item
                @click="openModal('journey-automation')"
                v-if="status"
                >Automação jornada</b-dropdown-item
              >
              <b-dropdown-item
                @click="openModal('pipeline-automation')"
                v-if="status"
                >Automação CRM</b-dropdown-item
              >
              <b-dropdown-item
                @click="deletarLeads()"
                class="red-item"
                v-if="status"
                >Deletar leads</b-dropdown-item
              >
              <b-dropdown-divider v-if="status"></b-dropdown-divider>
              <b-dropdown-item
                @click="redirect('/dynamicRoute/apps/tags')"
                v-if="status && !isIframe()"
                >Gestão de tags</b-dropdown-item
              >
              <b-dropdown-item @click="redirecionar" v-if="status && !isIframe()"
                >Importar leads</b-dropdown-item
              >
              <b-dropdown-item @click="openModal('export-leads')"
                >Exportar leads</b-dropdown-item
              >
              <b-dropdown-item
                @click="openModal('leads-automation')"
                v-if="status"
                >Automação leads</b-dropdown-item
              >
            </b-dropdown>

            <b-dropdown id="extras-btns" dropright v-else>
              <template #button-content>
                <b-button id="actions-1" class="btn-table" title="Ações"
                  ><img src="@/assets/img/icons/plus.svg"
                /></b-button>
              </template>
              <b-dropdown-item @click="openModal('wpp-lead')" disabled
                >Enviar mensagem WhatsApp</b-dropdown-item
              >
              <b-dropdown-item @click="openModal('email-unico')" disabled
                >Enviar um e-mail</b-dropdown-item
              >
              <b-dropdown-item @click="openModal('gerence-tag')" disabled
                >Adicionar/remover tag</b-dropdown-item
              >
              <b-dropdown-item @click="openModal('seller-automation')" disabled
                >Automação vendedor</b-dropdown-item
              >
              <b-dropdown-item @click="openModal('journey-automation')" disabled
                >Automação jornada</b-dropdown-item
              >
              <b-dropdown-item
                @click="openModal('pipeline-automation')"
                v-if="this.$store.getters.recursos.ncrm >= 1"
                disabled
                >Automação CRM</b-dropdown-item
              >
              <b-dropdown-item @click="deletarLeads()" class="red-item"
                >Deletar leads</b-dropdown-item
              >
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item
                v-if="!isIframe()"
                @click="redirect('/dynamicRoute/apps/tags')"
                disabled
                >Gestão de tags</b-dropdown-item
              >
              <b-dropdown-item
                v-if="!isIframe()"
                @click="redirecionar" 
                disabled
                >Importar leads</b-dropdown-item
              >
              <b-dropdown-item disabled>Importar leads</b-dropdown-item>
              <b-dropdown-item @click="openModal('export-leads')"
                >Exportar leads</b-dropdown-item
              >
              <b-dropdown-item @click="openModal('leads-automation')" disabled
                >Automação leads</b-dropdown-item
              >
            </b-dropdown>
          </div>
          <div
            id="leads-data"
            class="d-flex align-items-center flex-acoes"
            style="gap: 10px"
          >
            <div class="datas">
              <date-range-picker
                ref="picker"
                opens="right"
                :locale-data="localeDate"
                :showWeekNumbers="false"
                :showDropdowns="true"
                :autoApply="false"
                :ranges="dateRanges"
                @update="fetchLeads(search)"
                v-model="dateRange"
                style="width: 180px"
              >
                <template
                  v-if="dateRange.startDate && dateRange.endDate"
                  v-slot:input="picker"
                >
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </template>
                <template v-else v-slot:input> Selecione as datas </template>
              </date-range-picker>
            </div>
            <b-form-group class="cadastro-type" label-for="type">
              <BaseSelect
                :selectModel="type_date"
                :array="typeList"
                trackid="value"
                trackname="text"
                returnid="value"
                selectLabel=""
                deselectLabel=""
                :allowEmpty="false"
                :watch="true"
                @change="changedType"
                style="width: 220px"
              />
            </b-form-group>
          </div>
        </div>

        <b-col cols="12" md="4" class="container-pesquisa">
          <div class="inputSearch" style="position: relative">
            <img src="@/assets/img/icons/search.svg" class="Pointer search" />
            <input
              type="text"
              placeholder="Pesquisar lead"
              @input="debounce"
              v-model="search"
              class="input-busca"
            />
          </div>
        </b-col>
      </div>
      <!-- ACOES MOBILE -->
      <div class="header-table" v-if="isMobile">
        <div class="flex-sale"></div>

        <b-col cols="12" md="4" class="container-pesquisa pd">
          <div class="inputSearch" style="position: relative">
            <img
              src="@/assets/img/icons/search.svg"
              class="Pointer search"
              style="margin-top: -4px"
            />
            <input
              type="text"
              placeholder="Pesquisar lead"
              @input="debounce"
              v-model="search"
              class="input-busca"
            />
          </div>
        </b-col>
      </div>
      <div
        v-if="!loading && selected_lead.length > 0"
        data-anima="top"
        class="all-leads-selected zoom"
        style="padding: 0 28px"
      >
        <div>
          <b-form-checkbox
            v-model="all_leads"
            :value="true"
            :unchecked-value="false"
          ></b-form-checkbox>
          <p v-if="!changeCompanylead">
            Selecionar todos os {{ total }} leads?
          </p>
          <p v-else>Selecionar todas as {{ total }} empresas?</p>
        </div>
        <p>
          {{ all_leads ? total : selected_lead.length }}
          {{
            changeCompanylead ? "empresas selecionadas" : "leads selecionados"
          }}
        </p>
      </div>
      <!-- HEADER -->
      <b-row class="Table-header mb-1 zoom" v-if="reloadTable && !isMobile">
        <b-col style="width: 50px" class="d-none d-md-block align-column" cols="1">
          <div
            id="checkbox-group-all"
            @change="clickCheckAll"
            class="input-check d-flex"
            name="flavour-all"
            style="padding-left: 14px"
          >
            <b-form-checkbox
              v-if="
                !all_leads &&
                selected_lead.length == leads.length &&
                leads.length != 0
              "
              :checked="true"
              :value="true"
              :unchecked-value="false"
              class="mr-3 checkbox-all"
            ></b-form-checkbox>
            <b-form-checkbox
              v-else-if="!all_leads && selected_lead.length != leads.length"
              :checked="false"
              :value="true"
              :unchecked-value="false"
              class="mr-3 checkbox-all"
            ></b-form-checkbox>
            <b-form-checkbox
              v-else
              v-model="all_leads"
              :value="true"
              :unchecked-value="false"
              class="mr-3 checkbox-all"
            ></b-form-checkbox>
            <span class="qualif">Qualif</span>
          </div>
        </b-col>

        <b-col
          :cols="calColum('Lead')"
          class="d-none d-md-block align-column"
          style="margin-right: -5px; padding-left: 25px"
        >
          <span style="margin-left: 7px">Lead</span>
        </b-col>

        <b-col
          :cols="calColum('Tag')"
          v-if="validColum('Tag')"
          :style="validSpacing('tag')"
          class="d-none d-md-block align-column"
        >
          Tag
        </b-col>

        <b-col
          :cols="calColum('Follow-up')"
          v-if="validColum('Follow-up')"
          class="d-none d-md-block align-column"
          :style="validSpacing('follow-up')"
          >Follow-up</b-col
        >

        <b-col
          :cols="calColum('Origem')"
          v-if="validColum('Origem')"
          class="d-none d-md-block align-column"
          :style="validSpacing('origin')"
          >Origem</b-col
        >

        <b-col
          :cols="calColum('Entrada')"
          v-if="validColum('Entrada')"
          class="d-none d-md-block align-column"
          :style="validSpacing('entry')"
          >Entrada</b-col
        >

        <b-col
          :cols="calColum('Vendedor')"
          class="align-column"
          v-if="validColum('Vendedor')"
          :style="validSpacing('seller')"
        >
        <div>
          <div v-if="hasPreSeller == 'true'" style="font-weight: 400;font-size: 13px;">
            Pré-Vendedor
          </div>
            Vendedor
        </div>
        </b-col>
        <b-col
          :cols="calColum('Último cadastro')"
          v-if="validColum('Último cadastro') && (!filters.campos_adicionais || !filters.campos_adicionais.length)"
          class="align-column"
          style="margin-right: 10px"
        >
          {{ show_origin_form ? 'Formulário de origem' : 'Último cadastro' }}
        </b-col>

        <b-col
          :cols="calColum('Último cadastro')"
          v-else-if="validColum('Último cadastro')"
          class="align-column"
          style="margin-right: 10px"
        >
          Campos adicionais
        </b-col>

        <b-col cols="1" style="max-width: 30px">
          <b-dropdown id="dropdown-lista" right text="Ver">
            <b-dropdown-item
              @click.native.capture.stop
              v-for="(item, index) in coluns_table"
              :key="index"
            >
              <b-form-checkbox
                v-model="item.active"
                :value="true"
                :unchecked-value="false"
                @change="changeColum()"
              >
              {{ item.name == 'Último cadastro' && filters.campos_adicionais && filters.campos_adicionais.length ? 'Campos adicionais' : item.name }}
              </b-form-checkbox>
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>

      <!-- HEADER MOBILE -->
      <b-row
        class="Table-header mb-1"
        style="
          padding: 15px !important;
          gap: 0 !important;
          justify-content: space-between;
        "
        v-if="reloadTable && isMobile && !reachedLimit"
      >
        <b-col cols="2" style="padding: 0 !important"> Qualif </b-col>
        <b-col cols="7" style="text-align: start; padding: 0 !important">
          Lead
        </b-col>
        <b-col cols="2" style="text-align: end; padding: 0 !important">
          Tag
        </b-col>
      </b-row>
      <b-row
        class="Table-header mb-1"
        v-if="reloadTable && isMobile && reachedLimit"
      >
        <b-col cols="9"> Lead </b-col>
        <b-col cols="3"> Tag </b-col>
      </b-row>

      <!-- BODY -->
      <template v-if="!loading && leads.length > 0 && !isMobile">
        <div class="zoom">
          <div
            v-for="lead in validConfigdLeads"
            :key="lead.id"
            class="Table-body"
            id="border-bottom"
            style="position: relative"
            @click="openModalIdLead('visualizar-lead', lead)"
          >
          <leadComponent
            :config_seller_can_see_lead_without_seller="config_seller_can_see_lead_without_seller" 
            :level="level"
            :lead="lead"
            :all_leads="all_leads"
            :coluns_table="coluns_table"
            :isIframe="isIframe"
            :hasPreSeller="hasPreSeller"
            :selected_lead="selected_lead"
            :sellers="sellers"
            :filters="filters"
            :leadsUnification="leadsUnification"
            :show_origin_form="show_origin_form"
            :tenantSubdomain="tenantSubdomain"
            @updateSelectedLead="updateSelectedLead"
            @returnPostMensage="emitReturnPostMensage"
          >
          </leadComponent>
        </div>
      </div>

      </template>

      <!-- BODY MOBILE -->
      <template v-if="!loading && leads.length > 0 && isMobile">
        <div v-if="!reachedLimit">
          <div
            v-for="item in validConfigdLeads"
            :key="item.id"
            class="Table-body"
            style="padding: 15px 20px"
          >
            <b-row class="Table-body2" :class="getStatus(item.status).class">
              <b-col
                style="width: 50px; padding: 0 0 0 5px"
                class="d-none d-flex"
                cols="2"
              >
                <div style="display: flex; justify-content: center">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1436_593)">
                      <path
                        d="M14 14.76V3.5C14 2.83696 13.7366 2.20107 13.2678 1.73223C12.7989 1.26339 12.163 1 11.5 1C10.837 1 10.2011 1.26339 9.73223 1.73223C9.26339 2.20107 9 2.83696 9 3.5V14.76C8.19728 15.2963 7.58832 16.0766 7.26307 16.9856C6.93782 17.8946 6.91352 18.8841 7.19376 19.8079C7.47399 20.7317 8.04391 21.541 8.81934 22.1161C9.59476 22.6912 10.5346 23.0017 11.5 23.0017C12.4654 23.0017 13.4052 22.6912 14.1807 22.1161C14.9561 21.541 15.526 20.7317 15.8062 19.8079C16.0865 18.8841 16.0622 17.8946 15.7369 16.9856C15.4117 16.0766 14.8027 15.2963 14 14.76Z"
                        :stroke="filterLeadscore(item.leadscore)"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1436_593">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  {{ item.leadscore ? item.leadscore : 0 }}º
                </div>
              </b-col>

              <b-col cols="7" class="dados-cliente" >
                <div class="photo-organize" >
                  <div class="lead-photo" v-if="photoLead(item)">
                    <img 
                      :src="getLeadphoto(item)"
                      style="border-radius: 50%; width: 40px; height: 40px"
                      alt="leadImage"
                    />
                  </div>
                  <div class="lead-no-photo" v-else>
                    <h3>
                      {{ getMetaInitialLead(item) }} 
                    </h3>
                  </div>
                </div>
              
                <div>
                  <div v-if="item.name" class="break long-name">
                    {{ item.name }}
                  </div>
  
                  <div
                    class="break long-email"
                    v-if="item.email"
                    :id="item.email"
                    v-b-tooltip="{title: item.email, placement: 'top',}"
                  >
                    {{ item.email }}
                  </div>
  
                  <div v-if="item.phone" class="break">
                    <a
                      v-if="!isIframe()"
                      :href="formatTel(item.phone)"
                      target="_blank"
                      class="whats-cliente"
                      >{{ item.phone }}<img src="@/assets/icons/whats.svg" alt=""
                    /></a>
                    <div
                      v-else
                      @click.stop="openLeadWpp(item.phone)"
                      style="cursor: pointer;"
                      class="whats-cliente balanc-decoration">
                        {{ item.phone }}  <img src="@/assets/icons/whats.svg" alt=""/>
                    </div>
                  </div>
                </div>
              </b-col>
              <!-- tag -->
              <b-col cols="2">
                <span
                  v-if="!item.tags.length"
                  style="
                    font-size: 14px;
                    margin-left: 18px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                  >Sem tag</span
                >
                <span
                  :class="'tag ' + filterTag(item.tags).color + '-tag'"
                  :title="filterTag(item.tags).name"
                  style="width: 80px !important"
                  v-else
                  >{{
                    filterTag(item.tags) ? filterTag(item.tags).name : "Sem tag"
                  }}</span
                >
              </b-col>
            </b-row>
          </div>
        </div>

        <div v-if="reachedLimit">
          <b-row
            class="Table-body"
            v-for="item in leads"
            :key="item.id"
            :class="getStatus(item.status).class"
          >
            <b-col cols="9" class="dados-cliente">
              <div v-if="item.name" class="break">
                {{
                  item.name && item.name.length > maxLength
                    ? item.name.substr(0, maxLength) + "..."
                    : item.name
                }}
              </div>

              <div
                class="break"
                v-if="item.email"
                :id="item.email"
                v-b-tooltip="{
                  title: item.email,
                  placement: 'top',
                }"
              >
                {{
                  item.email && item.email.length > 37
                    ? item.email.substr(0, 37) + "..."
                    : item.email
                }}
              </div>

              <div v-if="item.phone" class="break">
                <a
                  v-if="!isIframe()"
                  :href="formatTel(item.phone)"
                  target="_blank" 
                  class="whats-cliente"
                  >{{ item.phone }}<img src="@/assets/icons/whats.svg" alt=""
                /></a>
                <div
                  v-else
                  @click.stop="openLeadWpp(item.phone)"
                  style="cursor: pointer;"
                  class="whats-cliente balanc-decoration">
                    {{ item.phone }} <img src="@/assets/icons/whats.svg" alt=""/>
                </div>
              </div>
            </b-col>
            <!-- tag -->
            <b-col cols="3">
              <span v-if="!item.tags.length">Sem tag</span>
              <span v-else>{{
                filterTag(item.ultima_tag) && filterTag(item.ultima_tag).name
                  ? filterTag(item.tags).name
                  : "Sem tag"
              }}</span>
            </b-col>
          </b-row>
        </div>
      </template>

      <!-- <div
        v-if="loading"
        class="Table-body py-4 d-flex justify-content-center align-items-center"
      >
        <b-spinner variant="dark" label="Spinning"></b-spinner>
      </div> -->

      <div v-if="loading && !isMobile">
        <div v-for="(item, index) in 10" :key="index">
          <div class="d-flex align-items-center justify-content-around my-3">
            <b-skeleton
              width="40px"
              :style="`margin-right: -3%; margin-left: 15px`"
              height="30px"
            >
              ></b-skeleton
            >
            <div>
              <b-skeleton height="10px" width="120px" class="mt-2"></b-skeleton>
              <b-skeleton height="10px" width="140px" class="mt-2"></b-skeleton>
              <b-skeleton height="10px" width="80px" class="mt-2"></b-skeleton>
            </div>

            <div>
              <b-skeleton height="16px" width="60px" class="mt-2"></b-skeleton>
            </div>

            <div>
              <b-skeleton height="16px" width="60px" class="mt-2"></b-skeleton>
            </div>

            <div>
              <b-skeleton height="16px" width="60px" class="mt-2"></b-skeleton>
            </div>

            <div>
              <b-skeleton height="16px" width="60px" class="mt-2"></b-skeleton>
            </div>

            <div>
              <b-skeleton height="16px" width="60px" class="mt-2"></b-skeleton>
            </div>

            <div>
              <b-skeleton height="16px" width="60px" class="mt-2"></b-skeleton>
            </div>

            <div>
              <b-skeleton height="16px" width="100px" class="mt-2"></b-skeleton>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="loading && isMobile">
        <div v-for="(item, index) in 10" :key="index">
          <b-skeleton width="100%" height="60px"></b-skeleton>
        </div>
      </div>

      <b-row>
        <b-col>
          <Paginate
            v-if="leads.length > 0"
            :totalPages="pagination.totalPages"
            :activePage="pagination.currentPage"
            @to-page="toPage"
            @per-page="perPage"
          />
        </b-col>
      </b-row>

      <b-row
        v-if="!loading && !leads.length"
        class="Table-body justify-content-center"
      >
        <p class="nao-encontrado">Nenhum lead foi encontrado</p>
      </b-row>

      <!-- END BODY -->

      <!-- modals -->
      <PipelineAutomation
        ref="pipeline-automation"
        :leads="all_leads ? 'todos' : selected_lead"
        :filtros="filters"
        :type_date="type_date"
        :total="total"
      />
      <JourneyAutomation
        ref="journey-automation"
        :leads="all_leads ? 'todos' : selected_lead"
        :filtros="filters"
        :type_date="type_date"
      />
      <GerenceTag
        ref="gerence-tag"
        :selected_lead="all_leads ? 'todos' : selected_lead"
        :userData="userData"
        :filtros="filters"
        :type_date="type_date"
        :isIframe="isIframe()"
        @fetchLeads="fetchLeads"
      />
      <MensageWpp
        ref="wpp-lead"
        :leads="all_leads ? 'todos' : selected_lead"
        :filtros="filters"
        :type_date="type_date"
        :total="total"
        @wpp-lead="clearLeadsSelecteds"
      />
      <GerenceCompany
        :selected_lead="all_leads ? 'todos' : selected_lead"
        :filtros="filters"
        :type_date="type_date"
        @fetchLeads="fetchLeads"
      />
      <EmailUnico
        ref='email-unico'
        :leads="all_leads ? 'todos' : selected_lead"
        :totalLeads="total"
        :filtros="filters"
      />
      <LeadsAutomation
        ref="leads-automation"
        :leads="all_leads ? 'todos' : selected_lead"
        :filtros="filters"
        :type_date="type_date"
        @fetchLeads="fetchLeads"
      />
      <ExportLeads ref="export-leads" :all_leads="all_leads" :leads="selected_lead" :filtros="filters" />
    </div>
    <!-- EMPRESA LISTA -->
    <div class="container-table" v-if="changeCompanylead == true">
      <div class="box-total" v-if="!loading">
        <div class="total-vendas info">
          <b-form-checkbox
            v-model="status"
            @change="fetchLeads"
            name="is_main"
            size="lg"
            switch
          >
            <p class="info-checkbox" style="width: 0px">
              {{ status ? " Ativos " : "Inativos" }}
            </p>
          </b-form-checkbox>
        </div>
        <div>
          <p class="total-vendas">
            {{ isMobile ? "Empresas:" : "Total de empresas:" }}
            <span>{{ formatNumber(filterTotalAndResource) }}</span>
          </p>
        </div>

        <div></div>
      </div>
      <div v-else>
        <b-skeleton
          width="100%"
          height="95px"
          style="border-radius: 10px"
        ></b-skeleton>
      </div>

      <p
        style="
          margin: 0;
          font-size: 12px;
          position: relative;
          top: 10px;
          color: var(--gray05);
        "
      >
        <svg
          @click="fetchLeads"
          v-b-tooltip="{
            title: 'Recarregar',
            placement: 'top',
          }"
          style="cursor: pointer"
          class="mr-2"
          width="15"
          height="15"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23 4V10H17"
            stroke="#8c8a97"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20.49 15C19.84 16.8399 18.6096 18.4187 16.9842 19.4985C15.3588 20.5783 13.4265 21.1006 11.4784 20.9866C9.53038 20.8726 7.67216 20.1286 6.18376 18.8667C4.69536 17.6047 3.65743 15.8932 3.22637 13.9901C2.79531 12.0869 2.99448 10.0952 3.79386 8.31508C4.59325 6.53496 5.94954 5.06288 7.65836 4.12065C9.36717 3.17843 11.3359 2.81711 13.268 3.09116C15.2 3.3652 16.9906 4.25975 18.37 5.64001L23 10"
            stroke="#8c8a97"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        Ultima atualização: {{ time_get }}
      </p>

      <!-- ACOES -->
      <div class="header-table" v-if="!isMobile">
        <div class="flex-sale">
          <div class="d-flex align-items-center" v-if="!loading">
            <b-tooltip
              target="filtro-1"
              title="Aplicar filtro"
              placement="topright"
            />
            <b-button
              id="filtro-1"
              class="btn-table"
              @click="openFilters"
              title="Filtros"
              ><img src="@/assets/img/icons/filtro.svg"
            /></b-button>

            <b-tooltip
              target="add_lead"
              title="Adicionar empresa"
              placement="topright"
            />
            <b-tooltip
              target="add_lead"
              title="Adicionar empresa"
              placement="topright"
              v-if="reachedLimit"
            />
            <b-button
              v-else
              @click="openModal('add-lead')"
              class="btn-table"
              title="Adicionar empresa"
              id="add_lead"
              ><img src="@/assets/img/icons/add_lead.svg"
            /></b-button>
            <b-button
              @click="leadLimit()"
              class="btn-table"
              title="Adicionar empresa"
              id="add_lead"
              v-if="reachedLimit"
              ><img src="@/assets/img/icons/add_lead.svg"
            /></b-button>

            <b-tooltip target="actions-1" title="Ações" placement="topright" />

            <b-dropdown
              id="extras-btns"
              dropright
              v-if="!reachedLimit"
            >
              <template #button-content>
                <b-button id="actions-1" class="btn-table" title="Ações"
                  ><img src="@/assets/img/icons/dots_greenn.svg"
                /></b-button>
              </template>
              <b-dropdown-item @click="openModal('wpp-lead')" v-if="status"
                >Enviar mensagem WhatsApp</b-dropdown-item
              >
              <b-dropdown-item @click="openModal('email-unico')" v-if="status"
                >Enviar um e-mail</b-dropdown-item
              >
              <b-dropdown-item @click="openModal('gerence-tag')" v-if="status"
                >Adicionar / remover tag</b-dropdown-item
              >
              <!-- HIDDEN-FEATURE -->
              <b-dropdown-item
                @click="openModal('gerence-company')"
                v-if="status && false"
                >Adicionar / remover empresa</b-dropdown-item
              >
              <b-dropdown-item
                @click="openModal('seller-automation')"
                v-if="
                  level === 'seller'
                    ? this.config_seller_can_transfer_lead
                      ? this.config_seller_can_transfer_lead == 'true'
                      : true
                    : true && status
                "
                >Automação vendedor</b-dropdown-item
              >
              <b-dropdown-item
                @click="openModal('journey-automation')"
                v-if="status"
                >Automação jornada</b-dropdown-item
              >
              <b-dropdown-item
                @click="openModal('pipeline-automation')"
                v-if="status"
                >Automação CRM</b-dropdown-item
              >
              <b-dropdown-item
                @click="deletarLeads()"
                class="red-item"
                v-if="status"
                >Deletar empresas</b-dropdown-item
              >
              <!-- <b-dropdown-divider v-if="status"></b-dropdown-divider>
              <b-dropdown-item
                @click="redirect('/dynamicRoute/apps/tags')"
                v-if="status"
                >Gestão de tags</b-dropdown-item
              > -->
              <!-- <b-dropdown-item
                @click="openModal('leads-automation')"
                v-if="status"
                >Automação empresas</b-dropdown-item
              > -->
            </b-dropdown>

            <b-dropdown id="extras-btns" dropright v-else>
              <template #button-content>
                <b-button id="actions-1" class="btn-table" title="Ações"
                  ><img src="@/assets/img/icons/plus.svg"
                /></b-button>
              </template>
              <b-dropdown-item @click="openModal('wpp-lead')" disabled
                >Enviar mensagem WhatsApp</b-dropdown-item
              >
              <b-dropdown-item @click="openModal('email-unico')" disabled
                >Enviar um e-mail</b-dropdown-item
              >
              <b-dropdown-item @click="openModal('gerence-tag')" disabled
                >Adicionar/remover tag</b-dropdown-item
              >
              <b-dropdown-item @click="openModal('seller-automation')" disabled
                >Automação vendedor</b-dropdown-item
              >
              <b-dropdown-item @click="openModal('journey-automation')" disabled
                >Automação jornada</b-dropdown-item
              >
              <b-dropdown-item
                @click="openModal('pipeline-automation')"
                v-if="this.$store.getters.recursos.ncrm >= 1"
                disabled
                >Automação CRM</b-dropdown-item
              >
              <b-dropdown-item @click="deletarLeads()" class="red-item"
                >Deletar empresas</b-dropdown-item
              >
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item
                v-if="!isIframe()"
                @click="redirect('/dynamicRoute/apps/tags')"
                disabled
                >Gestão de tags</b-dropdown-item
              >
              <b-dropdown-item @click="openModal('leads-automation')" disabled
                >Automação empresas</b-dropdown-item
              >
            </b-dropdown>
          </div>
          <div v-else class="d-flex align-items-center">
            <b-skeleton type="avatar" class="mr-3"></b-skeleton>
            <b-skeleton type="avatar" class="mr-3"></b-skeleton>
            <b-skeleton type="avatar" class="mr-3"></b-skeleton>
          </div>

          <div
            id="leads-data"
            class="d-flex align-items-center flex-acoes"
            style="gap: 10px"
          >
            <div class="datas">
              <date-range-picker
                v-if="!loading"
                ref="picker"
                opens="right"
                :locale-data="localeDate"
                :showWeekNumbers="false"
                :showDropdowns="true"
                :autoApply="false"
                :ranges="dateRanges"
                @update="fetchLeads(search)"
                v-model="dateRange"
                style="width: 180px"
              >
                <template
                  v-if="dateRange.startDate && dateRange.endDate"
                  v-slot:input="picker"
                >
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </template>
                <template v-else v-slot:input> Selecione as datas </template>
              </date-range-picker>

              <b-skeleton
                v-else
                width="180px"
                height="50px"
                type="button"
                class="mr-2"
              ></b-skeleton>
            </div>
            <b-form-group
              class="cadastro-type"
              label-for="type"
              v-if="!loading"
            >
              <BaseSelect
                :selectModel="type_date"
                :array="types_list_empresa"
                trackid="value"
                trackname="text"
                returnid="value"
                selectLabel=""
                deselectLabel=""
                @change="changedType"
                style="width: 220px"
              />
            </b-form-group>
            <b-skeleton
              v-else
              width="221px"
              height="50px"
              type="button"
              class="mr-3"
            ></b-skeleton>
          </div>
        </div>

        <b-col cols="12" md="4" class="container-pesquisa" v-if="!loading">
          <div class="inputSearch" style="position: relative">
            <img src="@/assets/img/icons/search.svg" class="Pointer search" />
            <input
              type="text"
              placeholder="Pesquisar empresa"
              @input="debounce"
              v-model="search"
              class="input-busca"
            />
          </div>
        </b-col>
        <b-skeleton
          v-else
          type="button"
          width="516px"
          height="50px"
        ></b-skeleton>
      </div>
      <!-- ACOES MOBILE -->
      <div class="header-table" v-if="isMobile">
        <div class="flex-sale"></div>

        <b-col cols="12" md="4" class="container-pesquisa pd" v-if="!loading">
          <div class="inputSearch" style="position: relative">
            <img
              src="@/assets/img/icons/search.svg"
              class="Pointer search"
              style="margin-top: -4px"
            />
            <input
              type="text"
              placeholder="Pesquisar empresa"
              @input="debounce"
              v-model="search"
              class="input-busca"
            />
          </div>
        </b-col>
        <b-skeleton
          v-else
          type="button"
          width="100%"
          height="50px"
        ></b-skeleton>
      </div>
      
      <div
        v-if="
          !loading &&
          selected_lead.length === leads.length &&
          selected_lead.length > 0
        "
        data-anima="top"
        class="all-leads-selected zoom"
        style="padding-left: 28px"
      >
        <div>
          <b-form-checkbox
            v-model="all_leads"
            :value="true"
            :unchecked-value="false"
          ></b-form-checkbox>
          <p v-if="!changeCompanylead">
            Selecionar todos os {{ total }} leads?
          </p>
          <p v-else>Selecionar todas as {{ total }} empresas?</p>
        </div>
        <p>
          {{ all_leads ? total : selected_lead.length }}
          {{
            changeCompanylead ? "empresas selecionadas" : "leads selecionados"
          }}
        </p>
      </div>
      <!-- HEADER -->
      <b-row class="Table-header mb-1 zoom" v-if="reloadTable && !isMobile">
        <b-col style="width: 50px" class="d-none d-md-block" cols="1">
          <div
            id="checkbox-group-all"
            @change="clickCheckAll"
            class="input-check d-flex"
            name="flavour-all"
            style="padding-left: 14px"
          >
            <b-form-checkbox
              v-if="
                !all_leads &&
                selected_lead.length == leads.length &&
                leads.length != 0
              "
              :checked="true"
              :value="true"
              :disabled="all_leads"
              :unchecked-value="false"
              class="mr-3 checkbox-all"
            ></b-form-checkbox>
            <b-form-checkbox
              v-else-if="!all_leads && selected_lead.length != leads.length"
              :checked="false"
              :value="true"
              :disabled="all_leads"
              :unchecked-value="false"
              class="mr-3 checkbox-all"
            ></b-form-checkbox>
            <b-form-checkbox
              v-else
              v-model="all_leads"
              :disabled="all_leads"
              :value="true"
              :unchecked-value="false"
              class="mr-3 checkbox-all"
            ></b-form-checkbox>
            <span class="qualif">Qualif</span>
          </div>
        </b-col>

        <b-col
          :cols="calColum('Lead')"
          class="d-none d-md-block"
          style="margin-right: -5px"
        >
          <span style="margin-left: 5px">Empresa</span>
        </b-col>

        <b-col
          :cols="calColum('Tag')"
          v-if="validColum('Tag')"
          :style="validSpacing('tag')"
          class="d-none d-md-block"
        >
          Tag
        </b-col>

        <b-col
          :cols="calColum('Follow-up')"
          v-if="validColum('Follow-up')"
          class="d-none d-md-block"
          :style="validSpacing('follow-up')"
          >Follow-up</b-col
        >

        <b-col
          :cols="calColum('Origem')"
          v-if="validColum('Origem')"
          class="d-none d-md-block"
          :style="validSpacing('origin')"
          >Origem</b-col
        >

        <b-col
          :cols="calColum('Entrada')"
          v-if="validColum('Entrada')"
          class="d-none d-md-block"
          :style="validSpacing('entry')"
          >Entrada</b-col
        >

        <b-col
          :cols="calColum('Vendedor')"
          v-if="validColum('Vendedor')"
          :style="validSpacing('seller')"
        >
          Vendedor
        </b-col>

        <b-col
          :cols="calColum('Último cadastro')"
          v-if="validColum('Último cadastro')"
          class=""
          style="margin-right: 10px"
        >
          {{ show_origin_form ? 'Formulário de origem' : 'Último cadastro' }}
        </b-col>

        <b-col cols="1" style="max-width: 30px">
          <b-dropdown id="dropdown-lista" right text="Ver">
            <b-dropdown-item
              @click.native.capture.stop
              v-for="(item, index) in coluns_table"
              :key="index"
            >
              <b-form-checkbox
                v-model="item.active"
                :value="true"
                :unchecked-value="false"
                @change="changeColum()"
              >
                {{ item.name }}
              </b-form-checkbox>
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>

      <b-row
        class="Table-header mb-1"
        style="
          padding: 15px !important;
          gap: 0 !important;
          justify-content: space-between;
        "
        v-if="reloadTable && isMobile && !reachedLimit"
      >
        <b-col cols="2" style="padding: 0 !important"> Qualif </b-col>
        <b-col cols="7" style="text-align: start; padding: 0 !important">
          Empresa
        </b-col>
        <b-col cols="2" style="text-align: end; padding: 0 !important">
          Tag
        </b-col>
      </b-row>

     

      <!-- BODY -->
      <template v-if="!loading && leads.length > 0 && !isMobile">
        <div class="zoom">
          <div
            v-for="item in validConfigdLeads"
            :key="item.id"
            class="Table-body"
            id="border-bottom"
            style="position: relative"
          >
          <div style="text-decoration: none; color: var(--gray01)" 
            >
            <b-row class="Table-body2" :class="getStatus(item.status).class" @click="openModalIdLead('visualizar-lead',item)">
              <!-- id -->
              <b-col @click.stop="" style="width: 50px" class="d-none d-flex" cols="1">
                <b-form-checkbox-group
                  :id="`checkbox-group-` + item.id"
                  v-model="selected_lead"
                  @change="clickCheck"                  
                  class="input-check checkbody"
                  :data-id="item.id"
                  :disabled="
                    all_leads ||
                    (item.status != 'active' && item.status != 'inactive')
                  "
                  :name="`flavour-` + item.id"
                >
                  <b-form-checkbox :value="item.id"></b-form-checkbox>
                </b-form-checkbox-group>

                <div
                  style="
                    padding-left: 20px;
                    display: flex;
                    justify-content: center;
                  "
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1436_593)">
                      <path
                        d="M14 14.76V3.5C14 2.83696 13.7366 2.20107 13.2678 1.73223C12.7989 1.26339 12.163 1 11.5 1C10.837 1 10.2011 1.26339 9.73223 1.73223C9.26339 2.20107 9 2.83696 9 3.5V14.76C8.19728 15.2963 7.58832 16.0766 7.26307 16.9856C6.93782 17.8946 6.91352 18.8841 7.19376 19.8079C7.47399 20.7317 8.04391 21.541 8.81934 22.1161C9.59476 22.6912 10.5346 23.0017 11.5 23.0017C12.4654 23.0017 13.4052 22.6912 14.1807 22.1161C14.9561 21.541 15.526 20.7317 15.8062 19.8079C16.0865 18.8841 16.0622 17.8946 15.7369 16.9856C15.4117 16.0766 14.8027 15.2963 14 14.76Z"
                        :stroke="filterLeadscore(item.leadscore)"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1436_593">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  {{ item.leadscore ? item.leadscore : 0 }}º
                </div>
              </b-col>
              <!-- lead -->
              <b-col
                :cols="calColum('Lead')"
                class="d-none d-md-block dados-cliente"
              >
                <div style="display: flex; gap: 10px; align-items: center">
                  <div>
                    <!-- <img
                    v-if="
                      getLeadPhoto(item.metas) != '' &&
                      $store.getters.recursos.ncrm
                    "
                    :src="
                      getLeadPhoto(item.metas) == ''
                        ? `@/assets/icons/no-seller.svg`
                        : getLeadPhoto(item.metas)
                    "
                    style="border-radius: 50%; width: 35px; height: 35px"
                    alt="leadImage"
                  />
                  <img
                    v-else-if="$store.getters.recursos.ncrm"
                    src="@/assets/icons/no-seller.svg"
                    style="border-radius: 50%; width: 35px; height: 35px"
                    alt="leadImage"
                  /> -->
                  </div>
                  <div>
                    <div v-if="item.name">
                        {{
                          item.name && item.name.length > 20
                            ? item.name.substr(0, 20) + "..."
                            : item.name
                        }}
                    </div>

                    <div
                      v-if="item.email"
                      :id="item.email"
                      v-b-tooltip="{
                        title: item.email,
                        placement: 'top',
                      }"
                    >
                      {{
                        item.email && item.email.length > 20
                          ? item.email.substr(0, 20) + "..."
                          : item.email
                      }}
                    </div>

                    <div v-if="item.phone">
                      <a
                        v-if="!isIframe()"
                        :href="formatTel(item.phone)"
                        target="_blank"
                        class="whats-cliente"
                        >{{ item.phone
                        }}<img src="@/assets/icons/whats.svg" alt=""
                      /></a>
                      <div
                        v-else
                        @click.stop="openLeadWpp(item.phone)"
                        style="cursor: pointer;"
                        class="whats-cliente balanc-decoration">
                          {{ item.phone }} <img src="@/assets/icons/whats.svg" alt=""/>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
              <!-- tag -->
              <b-col
                :cols="calColum('Tag')"
                v-if="validColum('Tag')"
                class="d-none d-md-block tag-column"
              >
                <span v-if="!item.tags.length" style="font-size: 14px"
                  >Sem tag</span
                >
                <span
                  :class="'tag ' + filterTag(item.tags).color + '-tag'"
                  :title="filterTag(item.tags).name"
                  v-else
                  >{{ filterTag(item.tags).name }}</span
                >
              </b-col>
              <!-- follow-up -->
              <b-col
                :cols="calColum('Follow-up')"
                v-if="validColum('Follow-up')"
                class="d-none d-md-block"
              >
                <span v-if="item.follow_up">{{
                  item && item.follow_up | datetime
                }}</span>
                <span style="font-size: 14px" v-else>Sem follow-up</span>
              </b-col>

              <!-- origem -->
              <b-col
                v-if="validColum('Origem')"
                :cols="calColum('Origem')"
                class="d-none d-md-block"
              >
                <div
                  v-if="
                    item.origin !== 'Cadastro manual' &&
                    item.origin !== 'Cadastro pipeline' &&
                    item.origin !== 'Importacao' &&
                    item.origin !== 'LeadAds' &&
                    item.origin !== 'new-checkout' &&
                    item.origin !== 'G Whats'
                  "
                >
                  <span v-if="item && item.views && item.views.length"
                    >Pag:
                    <span style="font-weight: 500">{{
                      filterPage(item.views)
                    }}</span
                    ><br
                  /></span>
                  <span v-else
                    >Pag: <span style="font-weight: 500">Desconhecido</span><br
                  /></span>
                  <span v-if="item.forms"
                    >Form:
                    <span style="font-weight: 500">{{
                      filterForm(item.forms, true).title
                    }}</span
                    ><br
                  /></span>
                  <span v-else
                    >Form: <span style="font-weight: 500">Desconhecido</span
                    ><br
                  /></span>
                  <span v-if="item.journey"
                    >Jornada:
                    <span style="font-weight: 500">{{
                      item.journey
                    }}</span></span
                  >
                </div>
                <span v-else> {{ returnOrigin(item.origin) }}</span>
              </b-col>

              <!-- entrada -->
              <b-col
                v-if="validColum('Entrada')"
                :cols="calColum('Entrada')"
                class="d-none d-md-block"
                :title="item.created_at | datetime"
                style="
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  padding: 0 9px;
                "
              >
                {{ item.created_at | datetime }}
              </b-col>

              <!-- vendedor -->
              <b-col
                v-if="validColum('Vendedor')"
                :cols="calColum('Vendedor')"
                class="d-none d-md-block"
              >
              <div v-if="hasPreSeller == 'true'" style="font-weight: 400;font-size: 13px;">
                {{
                  filterPreSeller(item.pre_user_id)
                }}
              </div>
                {{
                  filterSeller(item.user_id)
                }}
              </b-col>

              <!-- ultimo cadastro -->
              <!-- :cols="calColum('Último cadastro')" -->
              <b-col
                v-if="validColum('Último cadastro')"
                :cols="calColum('Último cadastro')"
                id="ultimo-cadastro"
                class="d-none d-md-block"
              >
                <span v-if="item.forms" style="word-break: break-word"
                  >{{ filterForm(item.forms, this.show_origin_form).title }}<br
                /></span>
                <span
                  style="font-weight: 500"
                  v-if="filterForm(item.forms, this.show_origin_form).title != 'Desconhecido'"
                  >{{ item.register | datetime }}</span
                >
              </b-col>

              <b-col cols="1" md="1">
                <div class="Table-body-action">
                  <div
                    style="padding: 50px"
                    target="_blank"
                    ><img src="@/assets/img/icons/eye.svg"
                  /></div>
                </div>
              </b-col>
              <!-- <b-col cols="1" md="1" >
              <div class="Table-body-action"></div>
            </b-col> -->
            </b-row>
          </div>
          </div>
        </div>
      </template>

      <!-- BODY MOBILE -->
      <template v-if="!loading && leads.length > 0 && isMobile">
        <div v-if="!reachedLimit">
          <div 
            v-for="item in validConfigdLeads"
            :key="item.id"
            class="Table-body"
            style="padding: 15px 20px"
          >
            <b-row class="Table-body2" :class="getStatus(item.status).class">
              <b-col
                style="width: 50px; padding: 0 0 0 5px"
                class="d-none d-flex"
                cols="2"
              >
                <div style="display: flex; justify-content: center">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1436_593)">
                      <path
                        d="M14 14.76V3.5C14 2.83696 13.7366 2.20107 13.2678 1.73223C12.7989 1.26339 12.163 1 11.5 1C10.837 1 10.2011 1.26339 9.73223 1.73223C9.26339 2.20107 9 2.83696 9 3.5V14.76C8.19728 15.2963 7.58832 16.0766 7.26307 16.9856C6.93782 17.8946 6.91352 18.8841 7.19376 19.8079C7.47399 20.7317 8.04391 21.541 8.81934 22.1161C9.59476 22.6912 10.5346 23.0017 11.5 23.0017C12.4654 23.0017 13.4052 22.6912 14.1807 22.1161C14.9561 21.541 15.526 20.7317 15.8062 19.8079C16.0865 18.8841 16.0622 17.8946 15.7369 16.9856C15.4117 16.0766 14.8027 15.2963 14 14.76Z"
                        :stroke="filterLeadscore(item.leadscore)"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1436_593">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  {{ item.leadscore ? item.leadscore : 0 }}º
                </div>
              </b-col>
              <b-col cols="7" class="dados-cliente">
                <div v-if="item.name" class="break">
                  {{
                    item.name && item.name.length > 20
                      ? item.name.substr(0, 20) + "..."
                      : item.name
                  }}
                </div>

                <div
                  class="break"
                  v-if="item.email"
                  :id="item.email"
                  v-b-tooltip="{
                    title: item.email,
                    placement: 'top',
                  }"
                >
                  {{
                    item.email && item.email.length > 20
                      ? item.email.substr(0, 20) + "..."
                      : item.email
                  }}
                </div>

                <div v-if="item.phone" class="break">
                  <a
                    v-if="!isIframe()"
                    :href="formatTel(item.phone)"
                    target="_blank"
                    class="whats-cliente"
                    >{{ item.phone }}<img src="@/assets/icons/whats.svg" alt=""
                  /></a>
                  <div
                    v-else
                    @click.stop="openLeadWpp(item.phone)"
                    style="cursor: pointer;"
                    class="whats-cliente balanc-decoration">
                      {{ item.phone }}  <img src="@/assets/icons/whats.svg" alt=""/>
                  </div>
                </div>
              </b-col>
              <!-- tag -->
              <b-col cols="2">
                <span
                  v-if="!item.tags.length"
                  style="
                    font-size: 14px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    margin-left: 18px;
                  "
                  >Sem tag</span
                >
                <span
                  :class="'tag ' + filterTag(item.tags).color + '-tag'"
                  :title="filterTag(item.tags).name"
                  style="width: 80px !important"
                  v-else
                  >{{
                    filterTag(item.tags) ? filterTag(item.tags).name : "Sem tag"
                  }}</span
                >
              </b-col>
            </b-row>
          </div>
        </div>

        <div v-if="reachedLimit">
          <b-row
            class="Table-body"
            v-for="item in leads"
            :key="item.id"
            :class="getStatus(item.status).class"
          >
            <b-col cols="9" class="dados-cliente">
              <div v-if="item.name" class="break">
                {{
                  item.name && item.name.length > maxLength
                    ? item.name.substr(0, maxLength) + "..."
                    : item.name
                }}
              </div>

              <div
                class="break"
                v-if="item.email"
                :id="item.email"
                v-b-tooltip="{
                  title: item.email,
                  placement: 'top',
                }"
              >
                {{
                  item.email && item.email.length > 37
                    ? item.email.substr(0, 37) + "..."
                    : item.email
                }}
              </div>

              <div v-if="item.phone" class="break">
                <a v-if="!isIframe()"
                  :href="formatTel(item.phone)"
                  target="_blank"
                  class="whats-cliente"
                  >{{ item.phone }}<img src="@/assets/icons/whats.svg" alt=""
                /></a>
                <div
                  v-else
                  @click.stop="openLeadWpp(item.phone)"
                  style="cursor: pointer;"
                  class="whats-cliente balanc-decoration ">
                    {{ item.phone }} <img src="@/assets/icons/whats.svg" alt=""/>
                </div>
              </div>
            </b-col>
            <!-- tag -->
            <b-col cols="3">
              <span v-if="!item.tags.length">Sem tag</span>
              <span v-else>{{
                filterTag(item.ultima_tag) && filterTag(item.ultima_tag).name
                  ? filterTag(item.tags).name
                  : "Sem tag"
              }}</span>
            </b-col>
          </b-row>
        </div>
      </template>

      <!-- <div
        v-if="loading"
        class="Table-body py-4 d-flex justify-content-center align-items-center"
      >
        <b-spinner variant="dark" label="Spinning"></b-spinner>
      </div> -->

      <div v-if="loading && !isMobile">
        <div v-for="(item, index) in 10" :key="index">
          <div class="d-flex align-items-center justify-content-around my-3">
            <b-skeleton
              width="40px"
              :style="`margin-right: -3%; margin-left: 15px`"
              height="30px"
            >
            </b-skeleton>
            <div>
              <b-skeleton height="10px" width="120px" class="mt-2"></b-skeleton>
              <b-skeleton height="10px" width="140px" class="mt-2"></b-skeleton>
              <b-skeleton height="10px" width="80px" class="mt-2"></b-skeleton>
            </div>

            <div>
              <b-skeleton height="16px" width="60px" class="mt-2"></b-skeleton>
            </div>

            <div>
              <b-skeleton height="16px" width="60px" class="mt-2"></b-skeleton>
            </div>

            <div>
              <b-skeleton height="16px" width="60px" class="mt-2"></b-skeleton>
            </div>

            <div>
              <b-skeleton height="16px" width="60px" class="mt-2"></b-skeleton>
            </div>

            <div>
              <b-skeleton height="16px" width="60px" class="mt-2"></b-skeleton>
            </div>

            <div>
              <b-skeleton height="16px" width="60px" class="mt-2"></b-skeleton>
            </div>

            <div>
              <b-skeleton height="16px" width="100px" class="mt-2"></b-skeleton>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="loading && isMobile">
        <div v-for="(item, index) in 10" :key="index">
          <b-skeleton width="100%" height="60px"></b-skeleton>
        </div>
      </div>

      <b-row>
        <b-col>
          <Paginate
            v-if="leads.length > 0"
            :totalPages="pagination.totalPages"
            :activePage="pagination.currentPage"
            @to-page="toPage"
            @per-page="perPage"
          />
        </b-col>
      </b-row>

      <b-row
        v-if="!loading && !leads.length"
        class="Table-body justify-content-center"
      >
        <p class="nao-encontrado">Nenhuma empresa foi encontrada</p>
      </b-row>

      <!-- END BODY -->

      <!-- modals -->
      <PipelineAutomation
        ref="pipeline-automation"
        :leads="all_leads ? 'todos' : selected_lead"
        :filtros="filters"
        :type_date="type_date"
        :total="total"
      />
      <JourneyAutomation
        ref="journey-automation"
        :leads="all_leads ? 'todos' : selected_lead"
        :filtros="filters"
        :type_date="type_date"
      />
      <GerenceTag
        ref="gerence-tag"
        :selected_lead="all_leads ? 'todos' : selected_lead"
        :filtros="filters"
        :type_date="type_date"
        @fetchLeads="fetchLeads"
      />
      <MensageWpp
        ref="wpp-lead"
        :leads="all_leads ? 'todos' : selected_lead"
        :filtros="filters"
        :type_date="type_date"
        :total="total"
        @wpp-lead="clearLeadsSelecteds"
      />
      <GerenceCompany
        :selected_lead="all_leads ? 'todos' : selected_lead"
        :filtros="filters"
        :type_date="type_date"
        @fetchLeads="fetchLeads"
      />
      <EmailUnico
        ref='email-unico'
        :leads="all_leads ? 'todos' : selected_lead"
        :filtros="filters"
      />
      <LeadsAutomation
        ref="leads-automation"
        :leads="all_leads ? 'todos' : selected_lead"
        :filtros="filters"
        :type_date="type_date"
        @fetchLeads="fetchLeads"
      />
      <ExportLeads ref="export-leads" :filtros="filters" />
    </div>
    <FiltersModal @filter="filter"
    name="modal-filter"
    ref="modal-filter"
    :isIframe="isIframe()"
    :hasPreSeller="hasPreSeller"
    :user="user"
    :level="level"
    :sellers="sellers" 
    />
    <ModalAddLead
      idModal="add-lead"
      ref="add-lead"
      :sellers="sellers"
      :user="user"
      @add-lead="fetchLeads"
      :leadsUnification="leadsUnification"
    />
    <SellerAutomation
      ref="seller-automation"
      :selected_lead="all_leads ? 'todos' : selected_lead"
      @seller-automation="fetchLeads"
      :filtros="filters"
      :type_date="type_date"
      :sellers="sellers"
    />
    <ModalVisualizarLead
      ref="visualizar-lead"
      @returnPostMensage="emitReturnPostMensage"
      @update="updateLeadsToView"
      :id_lead_selected="id_lead_selected"
      :tags_props="tagList"
      :sellers="sellers"
      :inList="true"
      :forms="forms"
      :userLevelData="userLevelData"
      :recursosData="recursosData"
      :isIframe="isIframe()"
      :userData="userData"
      :leadsUnification="leadsUnification"
      />
  </div>
</template>
<script>
import ModalVisualizarLead from "../Pipeline/ModalVisualizarLead.vue";
import LeadService from "@/services/resources/LeadService";
import LeadListaService from "@/services/resources/LeadListaService";
import Paginate from "@/components/Paginate";
import leadComponent from "@/components/Leads/leadComponent";
import BaseHeader from "@/components/BaseHeader";
import FiltersModal from "@/components/Leads/filters";
import MensageWpp from "@/components/Leads/mensageWpp";
import EmailUnico from "@/components/Leads/emailUnico";
import GerenceTag from "@/components/Leads/gerenceTag";
import SellerAutomation from "@/components/Leads/sellerAutomation";
import GerenceCompany from "@/components/Empresas/gerenceCompany";
import ModalAddLead from "@/components/Leads/addLead";
import ExportLeads from "@/components/Leads/exportLeads";
import JourneyAutomation from "@/components/Leads/journeyAutomation";
import PipelineAutomation from "@/components/Leads/pipelineAutomation";
import LeadsAutomation from "@/components/Leads/leadsAutomation";
import DateRangePicker from "vue2-daterange-picker";
import Vue from "vue";
import moment from "moment-timezone";
import _ from "lodash";
import ListarTagService from "@/services/resources/ListarTagService";
const serviceListarTags = ListarTagService.build();
const serviceListaLeads = LeadListaService.build();
const serviceLeads = LeadService.build();
//
import FormService from "@/services/resources/FormService";
const serviceForm = FormService.build();

import SettingsService from "@/services/resources/SettingsService";
const serviceSettings = SettingsService.build();


import SellerListService from "@/services/resources/SellerListService";
const serviceSeller = SellerListService.build();

import { setupDB, getCachedData, cacheData } from "@/store/indexedDB.js";

export default {
  props: ['userData','usersData','tagsData','recursosData','productsData','userLevelData','subdomainData'],
  name: "ListagemVendas",
  components: {
    Paginate,
    MensageWpp,
    GerenceCompany,
    GerenceTag,
    JourneyAutomation,
    PipelineAutomation,
    SellerAutomation,
    BaseHeader,
    FiltersModal,
    ModalAddLead,
    DateRangePicker,
    LeadsAutomation,
    ExportLeads,
    EmailUnico,
    ModalVisualizarLead,
    leadComponent,
  },
  data() {
    return {
      tagList: [],
      hasPreSeller: true,
      id_lead_selected: [],
      cahceExecut: false,
      time_get: null,
      not_selected_lead: [],
      tipoDeFiltro: "broadcast",
      level: "admin",
      recursos:{},
      user:{},
      tenantSubdomain:'',
      config_seller_can_see_lead_without_seller: "",
      config_seller_can_transfer_lead: "",
      valid: true,
      invalid: false,
      changeCompanylead: false,
      all_leads: false,
      selected_lead: [],
      status: true,
      reloadTable: true,
      import_leads: [],
      coluns_table: [
        { name: "Tag", colum: 1, active: true },
        { name: "Entrada", colum: 1, active: true },
        { name: "Último cadastro", colum: 1, active: true },
        { name: "Follow-up", colum: 1, active: true },
        { name: "Vendedor", colum: 1, active: true },
        { name: "Origem", colum: 1, active: true },
      ],
      lead_count: 0,
      sellers: [],
      earnReport: 4,
      invoiceReport: 6,
      errorReport: false,
      buttonReportStatus: "white",
      exportFilter: [],
      initExport: false,
      leads: [],
      sale_id: -1,
      search: null,
      loading: true,
      loading_reports: true,
      total: 0,
      itens: 0,
      forms: [],
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
      filters: {},
      type_date: "register",
      types_list: [
        { value: "register", text: "Último cadastro" },
        { value: "created_at", text: "Entrada do lead" },
        { value: "user_updated_at", text: "Associação ao vendedor" }
      ],
      types_list_empresa: [
        { value: "register", text: "Último cadastro" },
        { value: "created_at", text: "Entrada da empresa" },
        { value: "user_updated_at", text: "Associação ao vendedor" },
      ],
      localeDate: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
        firstDay: 0,
      },
      dateRange: {
        startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
        endDate: moment(),
      },
      current_perpage: null,
      client: {
        width: 0,
      },
      tags: [],
      show_origin_form: false,
      modalAddLead: false,
      modalFilter: false,
      leadsUnification: false
    };
  },
  watch: {
    changeCompanylead(e) {
      if (!e) {
        this.filters.company_view = false;
      } else {
        this.filters.company_view = true;
      }
      var data = this.configFilter(null);
      this.fetchLeads();
    },
  },
  computed: {
    reachedLimit(){
      if(this.recursos && this.lead_count >= this.recursos.nleads){
        return true;
      }
      
      return false;
    },
    dynamicNavigation(){
      return this.isIframe() ? false : [{ link: this.$route.name, to: this.$route.fullPath }];
    },
    typeList() {
        let assocTagItem = { value: "assocTag", text: "Associação por tag" };
        if (this.filters.tag && this.filters.tag.length >= 1) {
          if (!this.types_list.find(item => item.value === 'assocTag')) {
            return this.types_list.concat(assocTagItem);
          }
        } else {
            this.types_list = this.types_list.filter(item => item.value !== 'assocTag');
            return this.types_list;
        }
    },
    filterTotalAndResource() {
      if (this.recursos && this.recursos.nleads) {
        return `${this.total}/${this.recursos.nleads}`;
      } else {
        return `${this.total}`;
      }
    },
    validConfigdLeads() {
      return this.leads.filter((item) => {
        return (
          item.user_id !== 0 ||
          this.level !== "seller" ||
          this.config_seller_can_see_lead_without_seller == "true"
        );
      });
    },
    isMobile() {
      return this.client.width <= 768;
    },
    active() {
      return this.$route.name;
    },
    isSeller() {
      return this.$store.getters.isSeller;
    },
    userid() {
      return this.$store.getters.getProfile.id;
    },
    maxLength() {
      return this.$store.getters["sidebarState"] ? 30 : 37;
    },
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      lastWeek.setHours(0, 0, 0, 0);

      return {
        Hoje: [today, today],
        // Ontem: [yesterday, yesterday],
        "Última semana": [lastWeek, today],
        "Este mês": [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth() + 1, 0),
        ],
        "Este ano": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
        "Último mês": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "Todo período": [
          new Date(2016, 0, 1),
          new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        ],
      };
    },
  },
  methods: {
    clearLeadsSelecteds(){
      this.selected_lead = []
    },
    setLeadsUnification(){
      this.$store.dispatch('getUniqueFlag', 'leads_unification')
        .then((resp) => {
          this.leadsUnification = resp;
        });
    },
    updateSelectedLead(leadID) {
      let leadIndex = this.selected_lead.indexOf(leadID)
      if (leadIndex < 0) {
        this.selected_lead.push(leadID)
        return
      }
      this.selected_lead.splice(leadIndex, 1)
    },
    updateDataIframeLeadList(dataKey, newValue) {
      const mapping = {
        tagsData: 'tagList',
        userLevelData: 'level',
        recursosData: 'recursos',
        userData: 'user',
        usersData: 'sellers',
        productsData: 'productsData',
      }
      if (mapping[dataKey]) {
        this[mapping[dataKey]] = newValue;
      }
    },
    leadCount(){
      serviceLeads
        .read({id: 'count'})
        .then((resp) => {
          this.lead_count = resp.count;
        })
    },
    showOrignForm(){
      serviceSettings
        .read('meta/show_origin_form')
        .then((resp) => {
          if(resp && resp.meta_value && resp.meta_value == 'true'){
            this.show_origin_form = true;
            const type_register_index = this.coluns_table.findIndex(x => x.name == 'Último cadastro');
            Vue.set(this.coluns_table[type_register_index], "name", "Formulário de origem");
          }
        })
    },
    formatNumber(number) {
      const [num1, num2] = number.split("/").map(Number);

      const formatNumber1 = num1.toLocaleString("pt-BR");
      const formatNumber2 = num2.toLocaleString("pt-BR");

      return `${formatNumber1}/${formatNumber2}`;
    },
    fetchConfigSeller() {
      var data = {
        id: "seller",
      };
      serviceSettings.read(data).then((resp) => {
        for (let i = 0; i < resp.length; i++) {
          const seller = resp[i];
          if (seller.meta_key === "seller_can_transfer_lead") {
            this.config_seller_can_transfer_lead = seller.meta_value;
          }
          if (seller.meta_key === "seller_can_see_lead_without_seller") {
            this.config_seller_can_see_lead_without_seller = seller.meta_value;
          }
          if (seller.meta_key === "has_pre_seller") {
            this.hasPreSeller = seller.meta_value;
          }
        }
      });
    },
    getVendedor(page = 1) {
      var data = {
        page: page,
        per_page: 1000,
      };
      if(this.isIframe()){
        return
      }
      serviceSeller
        .search(data)
        .then((resp) => {
          let tempSet = new Set([...this.sellers, ...resp.data]);

          this.sellers = [...tempSet];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    leadLimit() {
      this.$grToast.toast(
        "Limite de leads atingido, aumente seu limite em recursos ou delete leads antigos",
        {
          title: "Leads",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        }
      );
    },
    redirect(e) {
      this.$router.push(e);
    },
    deletarLeads() {
      if (this.selected_lead.length === 0) {
        this.$grToast.toast("Necessário selecionar algum lead", {
          title: "Leads",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
      } else {
        Vue.swal({
          title: "Deletar leads",
          text: `Deseja deletar os leads selecionados?`,
          type: "danger",
          showCancelButton: true,
          confirmButtonText: "Deletar",
          cancelButtonText: "Cancelar",
          customClass: "sweet-container",
          confirmButtonClass: "button button-danger mt-3 mb-3",
          cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
          buttonsStyling: false,
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            var data = this.configFilter(null);

            data.id = "/inactive";
            data.leads = this.all_leads ? 'todos' : this.selected_lead;
            data.page = undefined;
            data.pagina = undefined;
            data.filters = this.filters;

            serviceLeads.createId(data).finally(() => {
              this.selected_lead = [];
              this.loading = false;
              this.fetchLeads();
              this.$grToast.toast("Em breve os leads serão deletados", {
                title: "Leads",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
            });
          }
        });
      }
    },
    clickCheckAll(e) {
      var leads = this.leads;
      var array = [];
      var array2 = [];
      if (e == true) {
        for (let i = 0; i < leads.length; i++) {
          const element = leads[i];
          if (element.status === "active" || element.status === "inactive") {
            array.push(element.id);
          } else {
            array2.push(element.id);
          }
        }
        this.selected_lead = array;
        // this.all_leads = true;
        return;
      }
      if (this.selected_lead.length === this.leads.length) {
        this.selected_lead = [];
        this.not_selected_lead = [];
        this.all_leads = false;
        return;
      }
      if (
        this.selected_lead.length + this.not_selected_lead.length ===
        this.leads.length
      ) {
        this.selected_lead = [];
        this.not_selected_lead = [];
        this.all_leads = false;
        return;
      }

      for (let i = 0; i < leads.length; i++) {
        const element = leads[i];
        if (element.status === "active" || element.status === "inactive") {
          array.push(element.id);
        } else {
          array2.push(element.id);
        }
      }
      this.not_selected_lead = array2;
      this.selected_lead = array;
    },
    clickCheck(e) {
      this.selected_lead = e;
      this.all_leads = false;
    },
    async buscarTagsApi() {
      if (this.tagList.length) {
        return
      }
      if(this.isIframe()){
        this.tagList = this.tagsData
        return
      }
      const cachedData = await getCachedData("tags");
      if (cachedData) {
        this.tagList = cachedData;
      }
      serviceListarTags.search({ per_page: 1000 }).then((resp) => {
        this.tagList = resp.data;
      });
    },
    async openModalIdLead(data, lead, is_exit_tag = false) {
      await this.buscarTagsApi()
      this.id_lead_selected = lead;
      this.is_exit_tag = is_exit_tag;
      this.$grModal.show(data, this);
    },
    updateLeadsToView(){
      this.fetchLeads()
    },
    openModal(modal) {
      if (this.selected_lead.length === 0 && !['add-lead', 'modal-filter'].find(modalExcept => modalExcept == modal)) {
        this.$grToast.toast("Necessário selecionar algum lead", {
          title: "Leads",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
      } else {
        if(modal !=='visualizar-lead'){
          try{
            this.$grModal.show(modal, this);
          }catch{
            this.$bvModal.show(modal);
          }
        }
      }
    },
    validSpacing(column) {
      switch (column) {
        case "tag":
          if (!this.validColum("Último cadastro")) {
            return "padding-left: 0px";
          }
          return "";
        case "follow-up":
          if (!this.validColum("Último cadastro")) {
            return "padding-left: 15px";
          }
          return "padding-left: 25px";
        case "origin":
          if (!this.validColum("Último cadastro")) {
            return "padding-left: 12px";
          }
          return "padding-left: 25px";
        case "entry":
          if (!this.validColum("Último cadastro")) {
            return "padding-left: 10px";
          }
          return "padding-left: 10px";
        case "seller":
          if (!this.validColum("Último cadastro")) {
            return "padding-left: 10px";
          }
          return "padding-left: 20px";
      }
    },
    filterLeadscore(ls) {
      if (!ls) {
        return "#cecece";
      } else if (ls <= 0) {
        return `#43ff64d9`;
      } else if (ls <= 10) {
        return `#3FA9DE`;
      } else if (ls <= 20) {
        return `#3DA1DF`;
      } else if (ls <= 30) {
        return `#9ABD5C`;
      } else if (ls <= 40) {
        return `#37A0A4`;
      } else if (ls <= 50) {
        return `#A373A8`;
      } else if (ls <= 60) {
        return `#B19A86`;
      } else if (ls <= 70) {
        return `#EFC033`;
      } else if (ls <= 80) {
        return `#E2845B`;
      } else if (ls <= 90) {
        return `#D82984`;
      } else if (ls <= 100) {
        return `#D34F68`;
      } else if (ls <= 120) {
        return `#008E7B`;
      } else if (ls > 120) {
        return `var(--greenn)`;
      } else {
        return "#cecece";
      }
    },
    getMetaInitialLead(item) {
      let leadName = item.name
        if (leadName) {
          let name = leadName.split(" ")[0];
          let last_name = leadName.split(" ")[1] ?? undefined;
          if (!name && !last_name) {
            return '';
          }
          if (!last_name) {
            return name.substring(0, 2);
          }
          return name[0] + last_name[0];
        }
      return;
    },
    photoLead (item) {
      let metaValue = item.metas?.map(meta => meta.meta_value);
      return item.metas && item.metas.length > 0 && metaValue;
    },
    getLeadphoto(item) {
      let metaValue = item.metas.map(meta => meta.meta_value);

      let photo = metaValue;
      return photo;
    },
    filterSeller(id_seller) {
      var seller = this.sellers;

      var result = seller.find((x) => x.id === id_seller);
      if (!result) {
        return "Sem vendedor";
      }
      return result.first_name && result.first_name.length ? result.first_name : result.email.split("@")[0];
    },
    filterPreSeller(idPreSeller){
      var preSeller = this.sellers;
      var result = preSeller.find((x) => x.id === idPreSeller);
      if (!result) {
        return "Sem Pré-vendedor";
      }
      return result.first_name && result.first_name.length ? result.first_name : result.email.split("@")[0];
    },
    filterForm(forms, first_form) {
      var index = first_form ? 0 : forms.length - 1;
      if (index > -1) {
        var result = forms[index];
        if (result) {
          return result;
        }
        return { title: "Desconhecido", created_at: "" };
      }
      return { title: "Desconhecido", created_at: "" };
    },
    filterPage(views) {
      // return id_page;
      if (views.length) {
        return views[0].title;
      }
      return "Desconhecido";
    },
    filterTag(tags_assoc) {
      var index = tags_assoc.length - 1;
      if (index > -1) {
        let result = tags_assoc[index];
        return result ? result : { name: "Sem tag", color: "" };
      }
      return "Sem tag";
    },
    cutString(origem, type) {
      let result1 = origem.split(type)[1];
      if (!result1) return "Desconhecido";
      return result1.split("|")[0];
    },
    returnOrigin(origin) {
      switch (origin) {
        case "Cadastro manual":
          return "Cadastro manual";
        case "Importacao":
          return "Importação";
        case "Cadastro pipeline":
          return "Cadastro CRM";
        case "LeadAds":
          return "LeadAds";
        case "G Whats":
          return "G Whats";
        case "new-checkout":
          return "Greenn Checkout";
        default:
          return origin;
      }
    },
    calColum(col) {
      var dataItem = this.coluns_table;
      var result = dataItem.filter((x) => x.active === true);
      if (result.length === 6) {
        if (col === "Lead" || col === "Último cadastro" || col === "Formulário de origem") {
          return 2;
        } else if (col === "Origem") {
          return 2;
        }
        return 1;
      } else if (result.length === 5) {
        if (
          dataItem.find((obj) => obj.name === "Origem").active == false ||
          dataItem.find((obj) => (obj.name == "Último cadastro" || obj.name == "Formulário de origem")).active == false
        ) {
          if (col === "Lead") {
            return 3;
          } else if (
            col === "Origem" ||
            col === "Último cadastro"  || col === "Formulário de origem" ||
            col === "Tag"
          ) {
            return 2;
          }
          return 1;
        }
        if (col === "Lead") {
          return 3;
        } else if (col === "Origem" || col === "Último cadastro" || col === "Formulário de origem") {
          return 2;
        }
        return 1;
      } else if (result.length === 4) {
        return 2;
      } else if (result.length === 3) {
        if (col === "Lead") {
          return 4;
        } else {
          return 2;
        }
      } else if (result.length === 2) {
        if (col === "Lead") {
          return 4;
        }
        return 3;
      } else if (result.length === 1) {
        return 5;
      } else if (result.length === 0) {
        if (col === "Lead") {
          return 10;
        }
      } else {
        if (col === "Lead") {
          return;
        } else if (col === "Origem") {
          return 3;
        } else {
          return 2;
        }
      }
    },
    changeColum() {
      this.reloadTable = false;
      this.reloadTable = true;
      localStorage.setItem("coluns_table", JSON.stringify(this.coluns_table));
    },
    validColum(col) {
      var dataItem = this.coluns_table;
      if(col == "Último cadastro" && this.show_origin_form){
        col = "Formulário de origem";
      }
      var result = dataItem.find((x) => x.name === col);
      if (result) {
        return result.active;
      }
    },
    setName(item) {
      if (item.product) {
        // if(item.offer){
        //   return item.offer.name;
        // }else{
        return item.product.name;
        // }
      } else {
        // if(item.offer){
        //   return item.invoices[0].offer ? item.invoices[0].offer.name : item.invoices[0].product.name;
        // }else{
        return item.invoices[0].product.name;
        // }
      }
    },
    updateStatusAndFetchReports() {
      if (this.buttonReportStatus === "white") {
        this.buttonReportStatus = "black";
        this.fetchReports(this.search);
      } else if (this.buttonReportStatus === "black") {
        this.buttonReportStatus = "white";
      }
    },
    openLeadWpp(phone){
      let data ={
        type: 'openLead',
        href:this.formatTel(phone)
      }
      this.$emit('returnPostMensage', data)
    },
    emitReturnPostMensage(data){
      this.$emit('returnPostMensage', data)
    },
    formatTel(tel) {
      if (tel) {
        if (tel.toString().indexOf("+55") > -1) {
          var tell = tel.replace(/[() -]/g, "");
          if (tell[0] === "+") {
            tell = tell.substring(1);
          }
          if(this.isIframe()){
            return `https://wa.me/${tell}`
          }
          return `https://api.whatsapp.com/send?phone=${tell}`;
        } else {
          if(this.isIframe()){
            return `https://wa.me/${tel.replace(/[() -]/g,"")}`
          }
          return `https://api.whatsapp.com/send?phone=55${tel.replace(/[() -]/g,"")}`;
        }
      }
    },
    getStatus(status) {
      let r = {};
      if (status) {
        switch (status.toLowerCase()) {
          case "processing": {
            r = { text: "Processando", class: "is-light-blue" };
            break;
          }
          case "authorized": {
            r = { text: "Transação autorizada", class: "is-light-blue" };
            break;
          }
          case "active": {
            r = {
              text: "Pago",
              class: "is-green",
            };
            break;
          }
          case "refunded": {
            r = { text: "Estornado", class: "is-red" };
            break;
          }

          case "waiting_payment": {
            r = { text: "Aguardando pagamento", class: "is-warning" };
            break;
          }
          case "pending_approbation": {
            r = { text: "Liberação manual", class: "is-light-blue" };
            break;
          }

          case "pending_refund":
          case "refund_pending": {
            r = { text: "Reembolso pendente", class: "is-warning" };
            break;
          }
          case "refused": {
            r = { text: "Rejeitado", class: "is-red" };
            break;
          }
          case "chargedback": {
            r = { text: "Estorno", class: "is-warning" };
            break;
          }
          case "delayed": {
            r = { text: "Em processamento", class: "is-warning" };
            break;
          }
          default: {
            r = { text: status, class: "is-black" };
            break;
          }
        }
        return r;
      } else return { text: status, class: "is-black" };
    },
    handleResize() {
      // this.client.width = window.innerWidth;
      this.client.width = window.innerWidth;
    },
    changedType(event) {
      this.type_date = event;
      this.fetchLeads(this.search);
    },
    debounce: _.debounce(function () {
      this.fetchLeads(this.search);
    }, 500),
    openPanel(sale_id) {
      this.sale_id = sale_id;
      this.$router.push({ query: { s_id: this.sale_id } }).catch(() => {});

      setTimeout(() => {
        this.$refs.salePreview.openPanel(this.sale_id);
        this.loading = false;
      });
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchLeads(this.search);
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;
      this.fetchLeads(this.search);
    },
    filter(filters) {
      this.tipoDeFiltro = filters[0].type;
      this.filters = filters[0];
      this.import_leads = filters[1];
      this.pagination.currentPage = 1;
      if(filters[0].tag.length == 0 && this.type_date == "assocTag"){
        this.type_date = 'created_at';
      }
      this.fetchLeads();
    },
    configFilter(search) {
      if (search) {
        this.pagination.currentPage = 1;
      }
      let data = {
        filters: {
          importacoes: [],
          status: this.status === true ? "active" : "inactive",
          search: "",
          campanha: [],
          company: [],
          company_view: this.changeCompanylead,
          tag: [],
          tag_exceto: [],
          email: [],
          status_email: "",
          formulario: [],
          vendedor: [],
          funil: [],
          leadscore_minimo: "",
          leadscore_maximo: "",
          status_jornada: [],
          campos_adicionais: [],
          data_inicio: "",
          data_fim: "",
          acao_email_tempo: "",
          higiene: "",
          campo_data: this.type_date,
          data_inicial: null,
          data_final: null,
          data_inicial_follow: null,
          data_final_follow: null,
          board: [],
          pipeline: [],
          email_click: [],
          email_open: [],
          email_not_click: [],
          email_not_open: [],
          email_spams: [],
          email_unsubscribeds: [],
          email_bounces: [],
          email_droppeds: [],
          ids: this.import_leads,
          campaign_id: [],
          item_id: "",
          type: this.tipoDeFiltro,
        },
        leads: [],
        withs: ["Tags", "Views", "Photo", "Forms"],
        order: "DESC",
        orderby: this.type_date,
        page: this.pagination.currentPage,
        pagina: this.pagination.currentPage,
        total_pagina: this.pagination.perPage,
      };
      if (this.tipoDeFiltro) {
        data.filters.type = this.tipoDeFiltro;
      }

      if (this.$route.params.campaign_id) {
        data.filters.campaign_id = [parseInt(this.$route.params.campaign_id)];
        data.filters.item_id = parseInt(this.$route.params.item_id);
      }

      if (search != null && typeof search == "string" && search.length > 0) {
        data.filters.search = search;
      }

      if (this.dateRange.startDate != null && this.dateRange.endDate != null) {
        data.filters.data_inicial = moment
          .tz(this.dateRange.startDate, "America/Sao_Paulo")
          .subtract(1, "month")
          .format("YYYY-MM-DD");
        data.filters.data_final = moment
          .tz(this.dateRange.endDate, "America/Sao_Paulo")
          .format("YYYY-MM-DD");
      }

      Object.keys(this.filters).forEach(
        (key) =>
          (this.filters[key] == null || this.filters[key].length === 0) &&
          delete this.filters[key]
      );
      data.filters = Object.assign(data.filters, this.filters);

      return data;
    },
    getLeadPhoto(meta) {
      let photo = meta.find((x) => x.meta_key == "photo-lead");
      if (photo) {
        return `${photo.meta_value}`;
      } else {
        return "";
      }
    },
    async fetchLeads(search = null) {
      this.leadCount();
      this.loading = true;
      var data = this.configFilter(search);
      this.filters = data.filters;
      const filteredProperties = Object.keys(data.filters).filter(
        (key) => data.filters[key] && data.filters[key].length > 0
      );
      if (filteredProperties.length <= 5) {
        const cachedData = await getCachedData(
          this.changeCompanylead ? "companies" : "leads"
        );
        this.time_get = await getCachedData("leads_time");
        if (cachedData && data.filters.status && this.cahceExecut === false) {
          this.leads = cachedData.data;
          this.pagination.totalPages = cachedData.last_page;
          this.total = cachedData.total;
          this.loading = false
          this.cahceExecut = true
        }
      }
      this.pagination.totalPages = 1;
      data.filters.status = this.status === true ? "active" : "inactive";
      data.filters.data_inicial = moment
        .tz(this.dateRange.startDate, "America/Sao_Paulo")
        .format("YYYY-MM-DD");
      data.filters.data_final = moment
        .tz(this.dateRange.endDate, "America/Sao_Paulo")
        .format("YYYY-MM-DD");
      data.filters.search = this.search;
      data.filters.company_view = this.changeCompanylead;
      if (this.filters.campos_adicionais && this.filters.campos_adicionais.length) {
          if (!data.withs.includes("Metas")) {
              data.withs.push("Metas");
          }
      } else {
          let index = data.withs.indexOf("Metas");
          if (index > -1) {
              data.withs.splice(index, 1);
          }
      }
      serviceListaLeads
        .create(data)
        .then((response) => {
          var time_get = this.saveDate();
          this.time_get = time_get;
          if (filteredProperties.length <= 5) {
            cacheData("leads_time", time_get);
          }
          if (data.filters.company_view) {
            var newResponse = response;
            for (let i = 0; i < response.data.length; i++) {
              const element = response.data[i];
              let newName =
                element.companies[0].legal_name ?? element.companies[0].name;
              newResponse.data[i].name = newName;
            }
            if (filteredProperties.length <= 5) {
              cacheData("companies", newResponse);
            }
            this.leads = newResponse.data;
          } else {
            if (filteredProperties.length <= 5) {
              cacheData("leads", response);
            }
            this.leads = response.data;
          }
          this.pagination.totalPages = response.last_page;
          this.total = response.total;
          if (this.all_leads) {
            this.clickCheckAll(true);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveDate() {
      const dataAtual = new Date();
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      const dataFormatada = dataAtual.toLocaleDateString("pt-BR", options);

      return dataFormatada;
    },
    searchByFilter() {
      this.fetchLeads();
    },
    decodeDateFilter(date) {
      switch (date) {
        // hoje
        case "hoje": {
          return moment();
        }
        // ontem
        case "ontem": {
          return moment().subtract(1, "days");
        }
        // uma semana atras
        case "uma semana": {
          return moment().subtract(7, "days");
        }
        // primeiro dia do mes atual
        case "primeiro dia mes atual": {
          return moment().startOf("month");
        }
        // ultimo dia do mes atual
        case "ultimo dia mes atual": {
          return moment().endOf("month");
        }
        // primeiro dia do ANO atual
        case "primeiro dia ano": {
          return moment().startOf("year");
        }
        // ultimo dia do ANO atual
        case "ultimo dia ano": {
          return moment().endOf("year");
        }

        // primeiro dia do mes passado
        case "primeiro dia mes passado": {
          return moment().startOf("month").subtract(1, "month");
        }
        // ultimo dia do mes passado
        case "ultimo dia mes passado":
          return moment().endOf("month").subtract(1, "month").add(1, "days");
        default: {
          return date;
        }
      }
    },
    saveSearch(dados_pesquisa) {
      window.localStorage.sales_filters = {};
      dados_pesquisa.date_start = this.formatDateFilter(
        dados_pesquisa.date_start
      );
      dados_pesquisa.date_end = this.formatDateFilter(dados_pesquisa.date_end);
      window.localStorage.sales_filters = JSON.stringify(dados_pesquisa);
    },
    formatDateFilter(date) {
      switch (date) {
        // hoje
        case moment().format("YYYY-MM-DD"): {
          return "hoje";
        }
        // ontem
        case moment().subtract(1, "days").format("YYYY-MM-DD"): {
          return "ontem";
        }
        // uma semana atras
        case moment().subtract(7, "days").format("YYYY-MM-DD"): {
          return "uma semana";
        }
        // primeiro dia do mes atual
        case moment().startOf("month").format("YYYY-MM-DD"): {
          return "primeiro dia mes atual";
        }
        // ultimo dia do mes atual
        case moment().endOf("month").format("YYYY-MM-DD"): {
          return "ultimo dia mes atual";
        }
        // primeiro dia do ANO atual
        case moment().startOf("year").format("YYYY-MM-DD"): {
          return "primeiro dia ano";
        }
        // ultimo dia do ANO atual
        case moment().endOf("year").format("YYYY-MM-DD"): {
          return "ultimo dia ano";
        }

        // primeiro dia do mes passado
        case moment()
          .startOf("month")
          .subtract(1, "month")
          .format("YYYY-MM-DD"): {
          return "primeiro dia mes passado";
        }
        // ultimo dia do mes passado
        case moment().endOf("month").subtract(1, "month").format("YYYY-MM-DD"):
        case moment()
          .endOf("month")
          .subtract(1, "month")
          .add(1, "days")
          .format("YYYY-MM-DD"): {
          return "ultimo dia mes passado";
        }
        default: {
          return date;
        }
      }
    },
    redirecionar() {
      this.$router.push({ name: "Importacao" });
    },
    isIframe(){
      return window.self !== window.top
    }
  },
  created() {
    if (this.isIframe()) {
      this.level = this.userLevelData
      this.recursos = this.recursosData
      this.user = this.userData
      this.tenantSubdomain = this.subdomainData
      this.sellers = this.usersData
    } else {
      this.user = this.$store.getters.user.user
      this.level = this.$store.getters.user.user.level
      this.recursos = this.$store.getters.recursos
      this.tenantSubdomain = this.$store.getters.user.user.tenant_subdomain
    }
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.setLeadsUnification();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    setupDB()
      .then(() => {
        this.fetchLeads();
      })
      .catch((error) => {
        this.fetchLeads();
      });
    this.loading = true;
    this.showOrignForm();
    this.leadCount();
    this.showOrignForm();
    if (this.total >= this.recursos && this.recursos.nleads) {
      this.leadLimit();
    }
    this.fetchConfigSeller();
    this.getVendedor();
  },
};
</script>

<style lang="scss" scoped>
.Table-body {
  &:hover {
    transform: scale(1.01);
  }
}

.pd {
  padding-right: 0;
  padding-left: 0;
}

.break {
  word-break: break-all;
}

.img_mobile {
  width: 25px;
  position: relative;
  right: -20px;
}

.metas {
  list-style: none;
  font-size: 11px;

  li {
    word-break: break-all;
  }
}

.long-name {
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.long-email{
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.btn-table {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: rgba(237, 237, 240, 0.5);
  outline: none;
}

.btn-table:active {
  background: rgba(237, 237, 240, 0.5);
  border: none;
}

.btn-table img {
  filter: invert(50%);
}

.btn-table:first-child {
  background: var(--greenn2);
  margin-right: 15px;
}

.btn-table:first-child:active {
  background: var(--greenn2);
  border: none;
}

.btn-table:first-child img {
  filter: invert(0);
}

.btn-table:nth-child(3) {
  margin-left: 15px;
  margin-right: 20px;
}

.header-table {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  padding-top: 15px;
}

.lead-no-photo h3 {
  text-align: center; 
  color: var(--greenn); 
  padding-top: 7px; 
  font-size: 17px;
  text-transform: uppercase;
}

.lead-no-photo {
  margin: 0 !important; 
  border: var(--greenn) ,1px, solid;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: var(--greenn-transparent);
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo-organize {
  display: flex; 
  align-items:center; 
  justify-content: center;
  padding-right: 20px;
}
.flex-sale {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.datas .vue-daterange-picker {
  width: 175px;
  font-size: 14px !important;
  color: #81858e !important;
}

.reset-data {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  margin-left: 15px;
}

.input-busca {
  width: 100%;
  height: 50px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 10px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
  position: absolute;
  top: -35px;
  left: 16px;
}

.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.search {
  position: absolute;
  left: 36px;
  top: -18px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}

.container-pesquisa {
  position: relative;

  margin-top: 15px;
}

@media screen and (max-width: 1300px) {
  .search {
    left: 30px;
  }
}

.container-pesquisa {
  text-align: right;
  z-index: 1;
}

.btn-estatistica {
  margin-left: -30px;
}

.grid-report {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}

@media screen and (max-width: 768px) {
  .img_mobile {
    width: 20px;
  }

  .header-table {
    display: block;
    padding: 0;
  }

  .flex-sale {
    display: block;
  }

  .flex-sale div {
    margin-left: 0;
  }

  .flex-acoes {
    margin: 15px 0;
  }

  .container-pesquisa {
    text-align: left;
  }

  .search {
    left: 20px;
    top: 22px;
  }

  .input-busca {
    width: 100%;
    position: relative !important;
    top: 0;
    left: 0;
    bottom: 0;
  }

  .btn-estatistica {
    margin-left: 0;
    margin-bottom: 20px;
  }

  .grid-report {
    grid-template-columns: 1fr 1fr;
  }
}

.dados-cliente {
  display: flex;
}

.dados-cliente div {
  margin-bottom: 3px;
}

.dados-cliente div:nth-child(2) {
  font-weight: normal;
  font-size: 14px;
}

.offer-name {
  font-weight: normal !important;
  font-size: 13px !important;
}

.whats-cliente {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 13px;
  color: var(--greenn);
}
.balanc-decoration:hover{
  text-decoration: underline !important;
}
.whats-cliente svg {
  cursor: pointer;
}

.valor-venda {
  margin-left: 10px;
}

#id_venda {
  position: relative;
}

#id_venda::before {
  content: "";
  width: 15px;
  height: 15px;
  background: transparent;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -25px;
  top: 0;
}

.TWO_CREDIT_CARDS {
  position: absolute;
  left: 5px;
  top: -5px;
}

// tipo data
#type {
  margin-bottom: 0 !important;
  margin-left: 10px;
}

.Table-body2 {
  // padding: 15px;
  display: flex;
  align-items: center;
}

.Table-body2::before {
  left: 0px;
}

.button {
  width: 179px;
  height: 55px;
  border-radius: 10px;
  background: #f7f7f7;
  color: var(--gray01);
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button--black {
  background: var(--gray01);
  color: #fff;
}

.button:hover {
  transform: scale(1);
}

.inverted--black {
  filter: invert(100);
}

.title-report {
  color: var(--gray01);
  font-weight: bold;
  font-size: 20px;
}

.title-report-margin-top {
  margin-top: 41px;
}

.title-report-margin-bottom {
  margin-top: 41px;
}

.default {
  height: 19px;
  padding: 5px;
  overflow-x: hidden;
  font-weight: 600;
  font-size: 10px;
  color: #000;
  background: #0000003b;
  border-radius: 3px;
  // white-space: nowrap;
}

.success {
  height: 19px;
  padding: 5px;
  overflow-x: hidden;
  font-weight: 600;
  font-size: 10px;
  color: var(--greenn);
  background: var(--greenn) 3b;
  border-radius: 3px;
  // white-space: nowrap;
}

div#checkbox-group-all {
  position: relative;
  left: -5px;
}

.all-leads-selected {
  display: flex;
  font-weight: 600;
  font-size: 14px;
  justify-content: space-between;
  > div {
    display: flex;
  }
}

.cadastro-type {
  padding-bottom: 6px;
}

.tag {
  padding: 5px;
  border-radius: 10px;
  display: block;
  width: 100px !important;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  white-space: nowrap;
}

.info-tag {
  background: var(--blue2);
  color: var(--bluee);
}

.danger-tag {
  background: var(--red2);
  color: var(--red);
}

.warning-tag {
  background: var(--orange2);
  color: var(--orange);
}

.primary-tag {
  background: var(--greenn2);
  color: var(--greenn);
}

.success-tag {
  background: var(--blue2);
  color: var(--bluee);
  font-size: 14px;
}

.default-tag {
  background: rgba(170, 170, 170, 0.301);
  font-size: 14px;
}

.tag-column {
  display: grid !important;
  place-content: start !important;
  padding-left: 0px !important;
}

.qualif {
  margin-left: 5px;
}
.align-column{
  display: flex !important;
  align-items: center
}
.lead-no-photo {
  margin: 0 !important; 
  border: var(--greenn) ,1px, solid;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: var(--greenn-transparent);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1350px) {
  .zoom {
    zoom: 0.85;
  }

  .qualif {
    margin-left: -10px;
  }

  .checkbox-all {
    top: 3px;
  }
}

@media (max-width: 1180px) {
  .zoom {
    zoom: 0.7;
  }
}

@media (max-width: 1000px) {
  .zoom {
    zoom: 0.6;
  }
}
</style>
