<template>
  <span class="leads-filter">
    <!-- filtros -->
    <GrModal
      name="modal-filter"
      ref="modal-filter"
      id="modal-filter"
      size="lg"
      title="Filtros"
      @shown="openModal"
      @shown.once="setVendedores()"
    >
      <b-row>
        <b-col cols="12" style="min-height: 490px">
          <b-tabs
            nav-class="TabHeader no-border"
            active-nav-item-class="TabHeader--active"
            :no-nav-style="true"
          >
            <b-tab :title="`Filtros gerais`" class="b-tab-filters">
              <!-- vendedores -->
              <b-row style="gap: 10px 0;">
                <b-col cols="6">
                  <div class="add-link-body"> 
                    <label class="mr-1">Vendedores</label>
                    <router-link
                      v-if="
                        !sellers.length &&
                        stops.includes('seller') &&
                        canShowOption()
                      "
                      :to="{ name: 'Usuarios' }"
                      class="add-link"
                      >Deseja criar um vendedor?</router-link
                    >
                  </div>
                  <BaseSelect
                    class="fit-height"
                    :clear="clear"
                    :selectModel="vendedor"
                    placeholder="Pesquise pelo e-mail"
                    trackname="email"
                    specificType="sellers"
                    track-by="id"
                    :array="vendedores"
                    :multiple="true"
                    :searchable="true"
                    :loading="loading"
                    :internal-search="true"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :show-no-results="true"
                    :hide-selected="true"
                    selectLabel=""
                    deselectLabel=""
                    selectedLabel=""
                    @change="vendedor = $event"
                    ><span slot="noResult"
                      >Nenhum vendedor encontrado.</span
                    ></BaseSelect
                  >
                </b-col>
                <b-col cols="6" v-if="hasPreSeller == 'true'">
                  <div class="add-link-body"> 
                    <label class="mr-1">Pré-Vendedores</label>
                  </div>
                  <BaseSelect
                    :clear="clear"
                    :selectModel="preVendedor"
                    placeholder="Pesquise pelo e-mail"
                    trackname="email"
                    specificType="sellers"
                    track-by="id"
                    :array="vendedores"
                    :multiple="true"
                    :searchable="true"
                    :loading="loading"
                    :internal-search="true"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :show-no-results="true"
                    :hide-selected="true"
                    selectLabel=""
                    deselectLabel=""
                    selectedLabel=""
                    @change="preVendedor = $event"
                    ><span slot="noResult"
                      >Nenhum pré-vendedor encontrado.</span
                    ></BaseSelect
                  >
                </b-col>

                <b-col cols="6">
                  <div>
                    <label class="mr-1">Data do Follow-up</label>
                  </div>
                  <date-range-picker
                    v-if="!loading"
                    ref="picker"
                    opens="left"
                    :locale-data="localeDate"
                    :showWeekNumbers="false"
                    :showDropdowns="true"
                    :autoApply="false"
                    :ranges="dateRanges"
                    v-model="dateRange3"
                    style="width: 320.56px;"
                  >
                  </date-range-picker>
                </b-col>

                <b-col cols="6">
                  <div class="add-link-body">
                    <label class="mr-1">Tags marcadas</label>
                    <router-link
                      v-if="
                        !tags.length &&
                        stops.includes('tag') &&
                        verificUserLevel && 
                        !isIframe
                      "
                      :to="{ name: 'Tags' }"
                      class="add-link"
                      >Deseja criar uma tag?</router-link
                    >
                  </div>

                  <BaseSelect
                    class="fit-height"
                    :clear="clear"
                    :selectModel="tagsMarcadas"
                    id="ajax"
                    track-by="id"
                    trackname="name"
                    selectLabel=""
                    deselectLabel=""
                    selectedLabel=""
                    open-direction="bottom"
                    :array="tags"
                    :multiple="true"
                    :searchable="true"
                    @search="debounceTags($event)"
                    :internal-search="true"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :show-no-results="true"
                    :hide-selected="true"
                    specificType="tags"
                    @callback="getTags($event)"
                    :watch="true"
                    @change="decideTagsFilter($event)"
                    :loading="loadingTags"
                  >
                    <span slot="noOptions">Pesquise pela tag</span>

                    <span slot="noResult">Oops! Nenhuma tag encontrada.</span>
                  </BaseSelect>

                  <b-form-checkbox
                    v-model="checked_tag"
                    name="checked_tag"
                    switch
                    class="switch-tag"
                    v-if="tagsMarcadas.length >= 2"
                  >
                    <p>
                      Filtrar por todos que contém essas tags simultaneamente
                    </p>
                  </b-form-checkbox>
                </b-col>

                <b-col cols="6">
                  <div class="add-link-body">
                    <label class="mr-1">Tags não marcadas</label>
                    <router-link
                      v-if="
                        !tags.length &&
                        stops.includes('tag') &&
                        verificUserLevel &&
                        !isIframe
                      "
                      :to="{ name: 'Tags' }"
                      class="add-link"
                      >Deseja criar uma tag?</router-link
                    >
                  </div>
                  <BaseSelect
                    class="fit-height"
                    :clear="clear"
                    :selectModel="tag_nao_marcadas"
                    id="ajax"
                    trackname="name"
                    specificType="tags"
                    track-by="id"
                    selectLabel=""
                    deselectLabel=""
                    selectedLabel=""
                    open-direction="bottom"
                    :array="tags.filter(tag => tag.id != 0)"
                    :multiple="true"
                    :searchable="true"
                    :internal-search="true"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :options-limit="10"
                    :show-no-results="true"
                    :hide-selected="true"
                    :loading="loadingTags"
                    @search="debounceTags($event)"
                    @callback="getTags($event)"
                    @change="tag_nao_marcadas = $event"
                  >
                    <span slot="noOptions">Pesquise pela tag</span>

                    <span slot="noResult">Oops! Nenhuma tag encontrada.</span>
                  </BaseSelect>
                  <b-form-checkbox
                    v-model="checked_tag_nao_marcada"
                    name="checked_tag_nao_marcada"
                    switch
                    class="switch-tag"
                    v-if="tag_nao_marcadas.length >= 2"
                  >
                    <p>
                      Filtrar por todos que não contém essas tags
                      simultaneamente
                    </p>
                  </b-form-checkbox>
                </b-col>

                <b-col cols="6">
                  <BaseSelect
                    :clear="clear"
                    :selectModel="jornada"
                    id="ajax"
                    label="Jornada"
                    trackname="name"
                    track-by="id"
                    selectLabel=""
                    deselectLabel=""
                    selectedLabel=""
                    open-direction="bottom"
                    :array="jornadas"
                    :multiple="false"
                    :searchable="false"
                    :loading="loading"
                    :internal-search="true"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :options-limit="10"
                    :show-no-results="true"
                    :hide-selected="true"
                    @change="jornada = $event"
                  >
                    <span slot="noOptions">Pesquise pela jornada</span>

                    <span slot="noResult"
                      >Oops! Nenhuma jornada encontrada.</span
                    >
                  </BaseSelect>
                </b-col>
                <b-col cols="6" >
                  <div class="add-link-body">
                    <label class="mr-1">Formulário</label>
                    <router-link
                      v-if="
                        !forms.length &&
                        stops.includes('form') &&
                        verificUserLevel
                      "
                      :to="{ name: 'CampanhasLista' }"
                      class="add-link"
                      >Deseja criar um formulário?</router-link
                    >
                  </div>
                  <div v-if="showComponent">
                    <BaseSelect
                      :selectModel="formulario"
                      placeholder=""
                      deselectLabel=""
                      selectLabel=""
                      track-by="id"
                      :multiple="true"
                      :array="forms"
                      trackname="title"
                      :searchable="true"
                      :loading="loadingForms"
                      noResult="Nenhum formulário encontrado."
                      noOptions="Pesquise pelo formulário"
                      @change="formulario = $event"
                      @callback="getFormsTotal($event)"
                      @search="debounceForms($event)"
                      :watch="true"
                    >
                    </BaseSelect>
                  </div>
                </b-col>

                <b-col cols="6"  v-if="recursos.ncrm >= 1">
                  <div class="add-link-body">
                    <label class="mr-1">CRM</label>
                    <router-link
                      v-if="!pipelines.length && verificUserLevel"
                      :to="{ name: 'CRM' }"
                      class="add-link"
                      >Deseja criar um CRM?</router-link
                    >
                  </div>
                  <BaseSelect
                    :selectModel="pipeline"
                    id="ajax"
                    trackname="title"
                    track-by="id"
                    direction="top"
                    placeholder=""
                    @remove="removePipeline"
                    @select="selectedPipe"
                    :array="pipelines"
                    :multiple="false"
                    v-if="showComponentCrm"
                    :searchable="true"
                    @search="debounceCrm($event)"
                    @callback="fetchPipelines($event)"
                    @change="pipeline = $event"
                    :watch="true"
                  >
                    <span slot="noOptions">Pesquise pelo crm</span>

                    <span slot="noResult">Oops! Nenhum CRM encontrado.</span>
                  </BaseSelect>
                </b-col>

                <b-col cols="6"  v-if="recursos.ncrm < 1"> 
                </b-col>

                <b-col cols="6"  v-if="recursos.ncrm >= 1">
                  <b-form-group label="Board">
                    <multiselect
                      v-model="board"
                      id="ajax"
                      label="name"
                      :disabled="!pipeline.id"
                      :placeholder="pipeline.id ? '' : 'Selecione um CRM antes'"
                      track-by="id"
                      selectLabel=""
                      deselectLabel=""
                      selectedLabel=""
                      open-direction="bottom"
                      :options="boards"
                      :multiple="true"
                      :searchable="true"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :show-no-results="true"
                      :hide-selected="true"
                    >
                      <template slot="option" slot-scope="props">
                        <div class="option__desc" :class="`select-tag-${props.option.color}`">
                          <span class="option__title"><span class="select-tag-dot"></span>{{ props.option.name }}</span>
                        </div>
                      </template>
                      <span slot="noOptions">Pesquise pelo board</span>

                      <span slot="noResult"
                        >Oops! Nenhum board encontrado.</span
                      >
                    </multiselect>
                  </b-form-group>
                </b-col>

                <b-col cols="6" >
                  <div class="add-link-body">
                    <label class="mr-1">Empresas</label>
                  </div>
                  <BaseSelect
                    direction="top"
                    :clear="clear"
                    :selectModel="company"
                    id="ajax"
                    trackname="name"
                    track-by="id"
                    selectLabel=""
                    deselectLabel=""
                    selectedLabel=""
                    open-direction="bottom"
                    :array="companies"
                    :multiple="true"
                    :searchable="true"
                    :loading="loadingCompany"
                    :internal-search="true"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :options-limit="10"
                    :show-no-results="true"
                    :hide-selected="true"
                    @search="debounceCompany"
                    @callback="getCompanies($event)"
                    @change="company = $event"
                  >
                    <span slot="noOptions">Pesquise pela empresa</span>

                    <span slot="noResult"
                      >Oops! Nenhuma empresa encontrada.</span
                    >
                  </BaseSelect>
                </b-col>
                <!-- importações -->
                <b-col cols="6" >
                  <div class="add-link-body">
                    <label class="mr-1">Importações</label>
                    <router-link
                      v-if="
                        !lista_import.length && stops && typeof stops == 'object' &&
                        stops.includes('import') &&
                        verificUserLevel
                      "
                      :to="{ name: 'Importacao' }"
                      class="add-link"
                      >Deseja criar uma importação?</router-link
                    >
                  </div>
                  <BaseSelect
                    :clear="clear"
                    :selectModel="selected_import"
                    trackname="title"
                    track-by="id"
                    :array="lista_import"
                    direction="top"
                    @remove="removeImport"
                    :multiple="false"
                    :searchable="true"
                    :internal-search="false"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :show-no-results="true"
                    :hide-selected="false"
                    selectLabel=""
                    deselectLabel=""
                    selectedLabel=""
                    :loading="loadingImports"
                    @callback="fetchImport($event)"
                    @search="debounceImport($event)"
                    @change="
                      (selected_import = $event)
                    "
                    ><span slot="noResult"
                      >Nenhuma importação encontrada.</span
                    ></BaseSelect
                  >
                </b-col>

                <b-col cols="6">
                  <label>Temperatura</label>
                  <b-form-group
                    class="form-relative mb-4 temp-index"
                  >
                    <div class="temp-inputs">
                      <div>
                        <input type="number" v-model="leadscore[0]">
                      </div>
                      <div>
                        <input type="number" v-model="leadscore[1]">
                      </div>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="`Campos adicionais`" class="b-tab-filters">
              <div>
                <div
                  v-for="(item, index) in adicional"
                  :key="index"
                  class="additional"
                >
                  <div style="width: 50%" class="mt-2">
                    <b-form-group label="Campo adicional" label-for="meta_key">
                      <b-form-input
                        id="meta_key"
                        v-model="item.chave"
                        type="text"
                        placeholder="utm_source"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div style="width: 50%" class="mt-2">
                    <b-form-group
                      label="Valor campo adicional"
                      label-for="meta_value"
                    >
                      <b-form-input
                        id="meta_value"
                        v-model="item.valor"
                        type="text"
                        placeholder="google"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div style="display: flex; align-items: end">
                    <BaseButton
                      style="
                        height: 50px !important;
                        min-width: 50px;
                        padding: 0 !important;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                      variant="danger"
                      :disabled="index === 0 && adicional.length == 1"
                      @click="removeCampo(index)"
                    >
                      <img
                        :src="require('@/assets/img/icons/trash.svg')"
                        style="max-width: fit-content"
                      />
                    </BaseButton>
                  </div>
                </div>
                <b-form-checkbox
                  v-if="adicional.length > 1"
                  v-model="checked_utms"
                  name="checked_utms"
                  switch
                  class="switch-tag"
                >
                  <p>Filtrar por todos que contém essas utms simultaneamente</p>
                </b-form-checkbox>
                <div class="add-additional">
                  <div @click="addCampo">Adicionar campo</div>
                </div>
              </div>
            </b-tab>
            <b-tab :title="`Filtro por e-mail`" class="b-tab-filters">
              <!-- filtros do broadcast -->
              <!-- <b-row
                class="mt-3 w-100"
                style="justify-content: space-between; margin: 0"
              >
                <BaseButton variant="info2" @click="broadcast = !broadcast"
                  >Email {{ broadcast ? "ativo" : "desativado" }}</BaseButton
                >
              </b-row> -->
              <div class="top-filter">
                <div>
                  <p>
                    É possível filtrar pelos e-mails do funil ou do broadcast
                  </p>
                </div>
                <div style="display: flex; gap: 15px; align-items: center">
                  <BaseButton
                    variant="link-info"
                    class="link-documentacao"
                    id="limpar"
                    @click="mudarTipoFiltro = !mudarTipoFiltro"
                    >Filtrar pelo
                    {{ mudarTipoFiltro ? "funil" : "broadcast" }}</BaseButton
                  >
                </div>
              </div>
              <b-row class="mt-2" v-if="broadcast">
                <b-col cols="6" class="mt-2">
                  <b-form-group label="E-mails clicados">
                    <multiselect
                      placeholder=""
                      v-model="email_click"
                      id="ajax"
                      label="name"
                      track-by="id"
                      selectLabel=""
                      deselectLabel=""
                      selectedLabel=""
                      open-direction="top"
                      :options="emails_search"
                      :multiple="true"
                      :searchable="true"
                      :loading="loading"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="10"
                      :show-no-results="true"
                      :hide-selected="true"
                      @search-change="aux_email_search"
                    >
                      <span slot="noOptions">Pesquise pelo e-mail</span>

                      <span slot="noResult"
                        >Oops! Nenhum e-mail encontrado.</span
                      >
                    </multiselect>
                  </b-form-group>
                </b-col>
                <b-col cols="6" class="mt-2">
                  <b-form-group label="E-mails abertos">
                    <multiselect
                      v-model="email_open"
                      id="ajax"
                      placeholder=""
                      label="name"
                      track-by="id"
                      selectLabel=""
                      deselectLabel=""
                      selectedLabel=""
                      open-direction="top"
                      :options="emails_search"
                      :multiple="true"
                      :searchable="true"
                      :loading="loading"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="10"
                      :show-no-results="true"
                      :hide-selected="true"
                      @search-change="aux_email_search"
                    >
                      <span slot="noOptions">Pesquise pelo e-mail</span>

                      <span slot="noResult"
                        >Oops! Nenhum e-mail encontrado.</span
                      >
                    </multiselect>
                  </b-form-group>
                </b-col>
                <b-col cols="6" class="mt-2">
                  <b-form-group label="E-mails não clicados">
                    <multiselect
                      v-model="email_not_click"
                      placeholder=""
                      id="ajax"
                      label="name"
                      track-by="id"
                      selectLabel=""
                      deselectLabel=""
                      selectedLabel=""
                      open-direction="top"
                      :options="emails_search"
                      :multiple="true"
                      :searchable="true"
                      :loading="loading"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="10"
                      :show-no-results="true"
                      :hide-selected="true"
                      @search-change="aux_email_search"
                    >
                      <span slot="noOptions">Pesquise pelo e-mail</span>

                      <span slot="noResult"
                        >Oops! Nenhum e-mail encontrado.</span
                      >
                    </multiselect>
                  </b-form-group>
                </b-col>
                <b-col cols="6" class="mt-2">
                  <b-form-group label="E-mails não abertos">
                    <multiselect
                      v-model="email_not_open"
                      placeholder=""
                      id="ajax"
                      label="name"
                      track-by="id"
                      selectLabel=""
                      deselectLabel=""
                      selectedLabel=""
                      open-direction="top"
                      :options="emails_search"
                      :multiple="true"
                      :searchable="true"
                      :loading="loading"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="10"
                      :show-no-results="true"
                      :hide-selected="true"
                      @search-change="aux_email_search"
                    >
                      <span slot="noOptions">Pesquise pelo e-mail</span>

                      <span slot="noResult"
                        >Oops! Nenhum e-mail encontrado.</span
                      >
                    </multiselect>
                  </b-form-group>
                </b-col>
                <b-col cols="6" class="mt-2">
                  <b-form-group label="E-mails spams">
                    <multiselect
                      v-model="email_spams"
                      placeholder=""
                      id="ajax"
                      label="name"
                      track-by="id"
                      selectLabel=""
                      deselectLabel=""
                      selectedLabel=""
                      open-direction="top"
                      :options="emails_search"
                      :multiple="true"
                      :searchable="true"
                      :loading="loading"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="10"
                      :show-no-results="true"
                      :hide-selected="true"
                      @search-change="aux_email_search"
                    >
                      <span slot="noOptions">Pesquise pelo e-mail</span>

                      <span slot="noResult"
                        >Oops! Nenhum e-mail encontrado.</span
                      >
                    </multiselect>
                  </b-form-group>
                </b-col>
                <b-col cols="6" class="mt-2">
                  <b-form-group label="E-mails unsubscribeds">
                    <multiselect
                      v-model="email_unsubscribeds"
                      placeholder=""
                      id="ajax"
                      label="name"
                      track-by="id"
                      selectLabel=""
                      deselectLabel=""
                      selectedLabel=""
                      open-direction="top"
                      :options="emails_search"
                      :multiple="true"
                      :searchable="true"
                      :loading="loading"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="10"
                      :show-no-results="true"
                      :hide-selected="true"
                      @search-change="aux_email_search"
                    >
                      <span slot="noOptions">Pesquise pelo e-mail</span>

                      <span slot="noResult"
                        >Oops! Nenhum e-mail encontrado.</span
                      >
                    </multiselect>
                  </b-form-group>
                </b-col>
                <b-col cols="6" class="mt-2">
                  <b-form-group label="E-mails bounces">
                    <multiselect
                      v-model="email_bounces"
                      placeholder=""
                      id="ajax"
                      label="name"
                      track-by="id"
                      selectLabel=""
                      deselectLabel=""
                      selectedLabel=""
                      open-direction="top"
                      :options="emails_search"
                      :multiple="true"
                      :searchable="true"
                      :loading="loading"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="10"
                      :show-no-results="true"
                      :hide-selected="true"
                      @search-change="aux_email_search"
                    >
                      <span slot="noOptions">Pesquise pelo e-mail</span>

                      <span slot="noResult"
                        >Oops! Nenhum e-mail encontrado.</span
                      >
                    </multiselect>
                  </b-form-group>
                </b-col>
                <b-col cols="6" class="mt-2">
                  <b-form-group label="E-mails droppeds">
                    <multiselect
                      v-model="email_droppeds"
                      placeholder=""
                      id="ajax"
                      label="name"
                      track-by="id"
                      selectLabel=""
                      deselectLabel=""
                      selectedLabel=""
                      open-direction="top"
                      :options="emails_search"
                      :multiple="true"
                      :searchable="true"
                      :loading="loading"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="10"
                      :show-no-results="true"
                      :hide-selected="true"
                      @search-change="aux_email_search"
                    >
                      <span slot="noOptions">Pesquise pelo e-mail</span>

                      <span slot="noResult"
                        >Oops! Nenhum e-mail encontrado.</span
                      >
                    </multiselect>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>

      <template v-slot:footer="{ cancel }">
        <BaseButton
          variant="danger"
          id="limpar"
          class="mr-2"
          @click="limparFiltros"
          >Limpar filtros</BaseButton
        >
        <BaseButton variant="link-info" class="mr-2" @click="cancel">
          Cancelar
        </BaseButton>
        <BaseButton variant="primary" @click="onSubmit"> Filtrar </BaseButton>
      </template>
    </GrModal>
  </span>
</template>
<script>
import Multiselect from "vue-multiselect";
import _ from "lodash";
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment-timezone";

import EmailService from "@/services/resources/EmailService";
const serviceEmail = EmailService.build();

import PipelineService from "@/services/resources/PipelineService";
const servicePipeline = PipelineService.build();

import ImportService from "@/services/resources/ImportService";
const serviceImport = ImportService.build();

import TagService from "@/services/resources/TagService";
const serviceTags = TagService.build();

import CompanyService from "@/services/resources/CompanyService";
const serviceCompany = CompanyService.build();

import FormService from "@/services/resources/FormService";
const serviceForm = FormService.build();

export default {
  props: ["sellers" ,"user","isIframe", "hasPreSeller"],
  components: {
    Multiselect,
    DateRangePicker,
  },
  data() {
    return {
      mudarTipoFiltro: false,
      email_bounces: null,
      email_click: null,
      email_droppeds: null,
      email_not_click: null,
      email_not_open: null,
      email_open: null,
      email_spams: null,
      email_unsubscribeds: null,
      recursos: this.$store.getters.recursos,
      emails_search: [],
      search: "",
      checked_utms: false,
      checked_tag: false,
      checked_tag_nao_marcada: false,
      broadcast: true,
      type_action: "nao_abertos",
      types_list: [
        { value: "nao_abertos", text: "Não abriram nenhum e-mail" },
        { value: "nao_clicados", text: "Não clicaram em nenhum e-mail" },
      ],
      board: [],
      boards: [],
      pipeline: "",
      pipelines: [],
      jornada: [],
      jornadas: [
        { id: 0, name: "Todas" },
        { id: "m", name: "Marketing" },
        { id: "V", name: "Vendas" },
      ],
      filter2: false,
      selected_import: [],
      lista_import: [],
      leads: [],
      total: 0,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
      formulario: [],
      loading: false,
      vendedor: [],
      tagsMarcadas: [],
      preVendedor: [],
      tag_nao_marcadas: [],
      companies: [],
      company: [],
      leadscore: [undefined, undefined],
      adicional: [{ chave: "", valor: "" }],
      localeDate: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
        firstDay: 0,
      },
      dateRange1: {
        startDate: moment(),
        endDate: moment(),
      },
      dateRange3: {
        startDate: null,
        endDate: null
      },
      clear: false,
      vendedores: [],
      tags: [
        {
          id: 0,
          name: "Nenhuma tag marcada",
          color: "default"
        }
      ],
      forms: [],
      stops: [],
      stopsCrm: [],
      already_all_tags: false,
      already_all_company: false,
      showComponent: true,
      showComponentCrm: true,
      pageCrm: "",
      searchTags: "",
      searchForm: "",
      searchImport: "",
      searchCompany: "",
      searchCrm: "",
      stopTags: false,
      stopCompany: false,
      stopForm: false,
      stopImport: false,
      loadingTags: false,
      loadingForms: false,
      loadingImports: false,
      loadingCompany: false,
      loadingCrm: false,
    };
  },
  computed: {
    verificUserLevel() {
      let user = this.user?.level ?? this.$store.getters.user.user.level;
      if (user === "owner" || user === "admin" || user == "gerent_seller") {
        return true;
      } else {
        return false;
      }
    },
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      lastWeek.setHours(0, 0, 0, 0);

      return {
        Hoje: [today, today],
        // Ontem: [yesterday, yesterday],
        "Última semana": [lastWeek, today],
        "Este mês": [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth(), 30),
        ],
        "Este ano": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
        "Último mês": [ //
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "Todo período": [
          new Date(2015, 0, 1),
          new Date(today.getFullYear() + 1, today.getMonth(), today.getDate()),
        ],
      };
    },
  },
  methods: {

    setVendedores() {
      let user = this.user?.level ?? this.$store.getters.user.user.level;
      if (user == 'seller') {
        this.vendedores = [ {id: 0,email: "Sem vendedor",}, this.user]
      } else {
        this.vendedores = this.sellers;
        this.vendedores.unshift({id: 0,email: "Sem vendedor",})
      }
    },
    //--------------------------------------------------------inicio
    debounceTags: _.debounce(function (query) {
      if (query === "") {
        this.loadingTags = false;
      } else {
        this.searchTags = query;
        this.getTags(this.page, query);
      }
    }, 500),

    getTags(page = 1, query = "") {
      if (this.stopTags == true) {
        return;
      }
      var data = {
        id: `list?page=${page}&order_by=name&order=ASC&search=${query}`,
      };

      serviceTags
        .read(data)
        .then((resp) => {
          if (resp.data.length < resp.per_page && query == "") {
            this.stopTags = true;
          }
          this.tags = this.tags.concat(resp.data);
          this.tags = this.tags.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .catch((error) => {
        })
        .finally(() => {
          this.loadingTags = false;
        });
    },
    //---------------------------------------------------------------fim

    //------------------------------------------------inicio
    decideTagsFilter(tags) {
      const hasNoTagFilter = tags.filter(tag => tag.id == 0);
      if(hasNoTagFilter.length){
        this.tagsMarcadas = hasNoTagFilter;
        return;
      }

      this.tagsMarcadas = tags;
    },
    //---------------------------------------------------------------fim

    //------------------------------------------------inicio
    debounceCompany: _.debounce(function (query) {
      if (query === "") {
        this.loadingCompany = false;
      } else {
        this.searchCompany = query;
        this.getCompanies(this.page, query);
      }
    }, 500),

    getCompanies(page = 1, query = "") {
      if (this.stopCompany == true) {
        return;
      }
      var data = {
        id: `list?page=${page}&order_by=name&order=ASC&search=${query}`,
      };

      serviceCompany
        .read(data)
        .then((resp) => {
          if (resp.data.length < resp.per_page && query == "") {
            this.stopCompany = true;
          }
          this.companies = this.companies.concat(resp.data);
          this.companies = this.companies.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .catch((error) => {
        })
        .finally(() => {
          this.loadingCompany = false;
        });
    },
    //---------------------------------------------------------------fim

    //------------------------------------inicio

    debounceForms: _.debounce(function (query) {
      if (query === "") {
        this.loadingForms = false;
      } else {
        this.searchForm = query;
        // this.forms = [];
        this.getFormsTotal(this.page, query);
      }
    }, 500),

    getFormsTotal(page = 1, query = "") {
      if (this.stopForm == true) {
        this.loadingForms = false;
        return;
      }
      var data = {
        id: `?page=${page}&order_by=title&order=ASC&status=active&search=${query}`,
      };
      serviceForm
        .read(data)
        .then((resp) => {
          if (resp.data.length < resp.per_page && query == "") {
            this.stopForm = true;
          }
          this.forms = this.forms.concat(resp.data);
          this.forms = this.forms.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .catch((error) => {
        });
    },
    canShowOption() {
      let level = this.$store.getters.user.user.level;
      if (level != "blogger" && level != "seller" && level != "templater") {
        return true;
      }
      return false;
    },

    getForms(page = 1) {
      if (this.stops.includes("form")) {
        return;
      }

      //------------------------------------fim

      serviceForm.read(data).then((resp) => {
        if (resp.data.length < resp.per_page) {
          this.stops.push("form");
        }
        const selectedFormularioIds = this.formulario.map((item) => item.id);
        this.forms = this.forms.concat(resp.data);
        this.formulario = this.forms.filter((item) =>
          selectedFormularioIds.includes(item.id)
        );
      });
    },
    aux_email_search(query) {
      this.loading = true;
      this.search = query;
      this.debounce_emails();
    },
    debounce_emails: _.debounce(function () {
      this.fetchEmails(this.search);
    }, 500),
    fetchEmails() {
      this.emails_search = [];

      var data = {
        status: "delivered",
        search: this.search,
        is_filter: true,
      };

      serviceEmail
        .search(data)
        .then((response) => {
          var emails = response;
          emails.forEach((element) => {
            this.emails_search.push({ id: element.id, name: element.title });
          });
          this.loading = false;
        })
        .catch((err) => {
          this.emails_search = [];
        });
    },
    limparFiltros() {
      this.dateRange3.startDate = null;
      this.dateRange3.endDate = null;
      this.pipeline = "";
      this.leads_import = [];
      this.selected_import = [];
      this.broadcast = true;
      this.type_action = "nao_abertos";
      this.board = [];
      this.jornada = [];
      (this.company = []), (this.formulario = []);
      this.vendedor = [];
      this.preVendedor = [];
      this.filter2 = false;
      this.tagsMarcadas = [];
      this.tag_nao_marcadas = [];
      this.leadscore = [undefined, undefined];
      this.adicional = [{ chave: "", valor: "" }];
      this.clear = true;
      setTimeout(() => {
        this.clear = false;
      }, 500);
    },
    selectedPipe(e) {
      this.boards = [];
      this.board = [];
      if (e.id != null) {
        var tags_selecteds = e.tags;
        var tags = [];
        for (let i = 0; i < tags_selecteds.length; i++) {
          const element = tags_selecteds[i];
          serviceTags.read(`/show/${element}`).then((resp) => {
            tags.push(resp);
          });
        }
        this.boards = tags;
      }
    },
    removePipeline() {
      this.boards = [];
    },
    openModalAvancado() {
      this.filter2 = true;
      this.$grModal.hide('modal-filter', this);
    },
    removeCampo(index) {
      let camposAdicionais = this.adicional;
      camposAdicionais.splice(index, 1);
      this.adicional = camposAdicionais;
      if (camposAdicionais.length < 2) {
        this.checked_utms = false;
      }
    },
    addCampo() {
      var adicional = this.adicional;
      adicional.push({ chave: "", valor: "" });
      this.adicional = adicional;
    },
    onSubmit() {
      const isDuplicate = this.tagsMarcadas.some((el) =>
        this.tag_nao_marcadas.includes(el)
      );
      if (isDuplicate) {
        this.$grToast.toast(
          "Tags marcadas precisam ser diferente de tags não marcadas",
          {
            title: "Filtros",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          }
        );
        this.loading = false;
        return;
      }
      // Validação caso o usuário troque os valores do range duplo
      if (this.leadscore[1] < this.leadscore[0]) {
        let a
        a = this.leadscore[1]
        this.leadscore[1] = this.leadscore[0]
        this.leadscore[0] = a
      }
      let data = {
        type: this.mudarTipoFiltro ? "funnel" : "broadcast",
        checked_tag: this.checked_tag,
        checked_utms: this.checked_utms,
        checked_tag_nao_marcada: this.checked_tag_nao_marcada,
        importacoes: this.selected_import ? this.selected_import.id : [],
        tag: this.tagsMarcadas.length
          ? this.tagsMarcadas.map(function (item) {
              return item.id + "";
            })
            : [],
        tag_exceto: this.tag_nao_marcadas.length
          ? this.tag_nao_marcadas.map(function (item) {
              return item.id + "";
            })
          : [],
        vendedor: this.vendedor.length
          ? this.vendedor.map(function (item) {
              return item.id + "";
            })
          : [],
          preVendedor: this.preVendedor.length
          ? this.preVendedor.map(function (item) {
              return item.id + "";
            })
          : [],
        formulario: this.formulario.length
          ? this.formulario.map(function (item) {
              return item.id + "";
            })
          : [],
        leadscore_maximo: this.leadscore[1] ?? undefined,
        leadscore_minimo: this.leadscore[0] ?? 0,
        campos_adicionais: this.adicional[0].chave !== "" ? this.adicional : [],
        status_jornada: this.jornada.id,
        pipeline: this.pipeline.id ? [this.pipeline.id] : [],
        board:
          this.board.length && this.pipeline.id
            ? this.board.map(function (item) {
                return item.id + "";
              })
            : [],

        higiene: this.filter2 ? "higiene" : "",
        data_inicial_follow: moment
              .tz(this.dateRange3.startDate, "America/Sao_Paulo")
              .format("YYYY-MM-DD") == 'Invalid date' ? null : moment
              .tz(this.dateRange3.startDate, "America/Sao_Paulo")
              .format("YYYY-MM-DD"),

        data_final_follow: moment
              .tz(this.dateRange3.endDate, "America/Sao_Paulo")
              .format("YYYY-MM-DD") == 'Invalid date' ? null : moment
              .tz(this.dateRange3.endDate, "America/Sao_Paulo")
              .format("YYYY-MM-DD"),
        data_inicio: this.filter2
          ? moment
              .tz(this.dateRange1.startDate, "America/Sao_Paulo")
              .format("YYYY-MM-DD")
          : [],
        data_fim: this.filter2
          ? moment
              .tz(this.dateRange1.startDate, "America/Sao_Paulo")
              .format("YYYY-MM-DD")
          : [],
        acao_email_tempo: this.filter2 ? this.type_action : "",

        email_bounces:
          this.broadcast && this.email_bounces
            ? this.email_bounces.map(function (item) {
                return item.id + "";
              })
            : [],
        email_click:
          this.broadcast && this.email_click
            ? this.email_click.map(function (item) {
                return item.id + "";
              })
            : [],
        email_droppeds:
          this.broadcast && this.email_droppeds
            ? this.email_droppeds.map(function (item) {
                return item.id + "";
              })
            : [],
        email_not_click:
          this.broadcast && this.email_not_click
            ? this.email_not_click.map(function (item) {
                return item.id + "";
              })
            : [],
        email_not_open:
          this.broadcast && this.email_not_open
            ? this.email_not_open.map(function (item) {
                return item.id + "";
              })
            : [],
        email_open:
          this.broadcast && this.email_open
            ? this.email_open.map(function (item) {
                return item.id + "";
              })
            : [],
        email_spams:
          this.broadcast && this.email_spams
            ? this.email_spams.map(function (item) {
                return item.id + "";
              })
            : [],
        email_unsubscribeds:
          this.broadcast && this.email_unsubscribeds
            ? this.email_unsubscribeds.map(function (item) {
                return item.id + "";
              })
            : [],
        company: this.company
          ? this.company.map(function (item) {
              return item.id + "";
            })
          : [],
        company_view: this.company.length ? true : false,
      };

      this.$emit("filter", [data, this.leads_import]);

      this.$grModal.hide('modal-filter', this);
    },

    //----------------------------------------------inicio
    debounceImport: _.debounce(function (query) {
      if (query === "") {
        this.resetComponent(); // Chama o método de reiniciar o componente
      } else {
        this.searchImport = query;
        // this.forms = [];
        this.fetchImport(this.page, query);
      }
    }, 500),
    resetComponent() {
      this.page = 1; // Redefine a página para 1
      this.searchImport = ""; // Limpa a pesquisa
      // this.forms = [];
      this.showComponent = false; // Esconde o componente
      this.$nextTick(() => {
        this.showComponent = true; // Exibe o componente novamente
      });
    },
    fetchImport(page = 1, query = "") {
      if (this.stopImport == true) {
        return;
      }
      var data = {
        id: `/done?page=${page}&order_by=title&order=ASC&search=${query}`,
        status: "done",
      };
      serviceImport
        .read(data)
        .then((resp) => {
          if (resp.data.length < resp.per_page && query == "") {
            this.stopImport = true;
          }
          this.lista_import = this.lista_import.concat(resp.data);
          this.lista_import = this.lista_import.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .catch((error) => {
        });
    },
    //----------------------------------------------fim
    removeImport() {
      this.leads_import = [];
    },

    //--------------------------------------inicio

    debounceCrm: _.debounce(function (query) {
      if (query === "") {
        this.resetComponentCrm();
      } else {
        this.searchCrm = query;
        this.pageCrm = 1;
        this.pipelines = [];
        this.stops = [];
        this.fetchPipelines(this.pageCrm, query);
      }
    }, 500),
    resetComponentCrm() {
      this.pageCrm = 1;
      this.searchCrm = "";
      this.pipelines = [];
      this.stops = [];
      this.showComponentCrm = false;
      this.$nextTick(() => {
        this.showComponentCrm = true;
      });
    },
    fetchPipelines(page = 1, query = "") {
      if (this.stops.includes("pipelines")) {
        return;
      }

      var data = {
        page: `${page}&order_by=title&order=ASC&search=${query}`,
      };
      servicePipeline
        .search(data)
        .then((response) => {
          var pipelines = response.data.map((element) => ({
            id: element.id,
            title: element.title,
            tags: element.tags,
          }));

          if (response.data.length < response.per_page) {
            this.stops.push("pipelines");
          }

          this.pipelines = this.pipelines.concat(pipelines);
          this.pipelines = this.pipelines.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .catch((err) => {
          // this.pipelines = [];
        });
    },
    //--------------------------------------fim
    // fetchPipelines(page = 1) {
    //   if (this.stops.indexOf("pipelines") != -1) {
    //     return;
    //   }
    //   var data = {
    //     page: page,
    //   };
    //   var pipelines = [];
    //   servicePipeline
    //     .search(data)
    //     .then((response) => {
    //       response.data.forEach((element) => {
    //         pipelines.push({
    //           id: element.id,
    //           title: element.title,
    //           tags: element.tags,
    //         });
    //       });

    //       if (response.data.length < response.per_page) {
    //         this.stops.push("pipelines");
    //       }
    //       this.pipelines = this.pipelines.concat(pipelines);
    //     })
    //     .catch((err) => {
    //       // this.pipelines = [];
    //     });
    // },

    openModal() {
      this.filter2 = false;
    },
  },
};
</script>

<style scoped lang="scss">
$tag-colors: (
  info: var(--bluee),
  danger: var(--red),
  warning: var(--orange),
  primary: var(--greenn),
  success: #4b2cbd,
  default: var(--gray02)
);

@mixin tags-dot($color) {
  .select-tag-dot {
    margin-right: 8px;
    margin-bottom: 1px;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: $color;
  }
}

@each $tag, $color in $tag-colors {
  .select-tag-#{$tag} {
    @include tags-dot($color);
  }
}

.select-seller-option {
  display: flex;
  align-items: center;
  gap: 15px;
  > div:first-child {
    width: 30px;
    height: 30px;
    background-color: var(--greenn2);
    border: 1px solid var(--greenn);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--greenn);
    text-transform: uppercase;
    background-size: cover;
    background-position: center;
    overflow: hidden;
  }
  > div:last-child {
    p:last-child {
      font-size: smaller;
    }
    p {
      margin-bottom: 0 !important;
      line-height: 1.3;
    }
  }
}

.temp-inputs{
  display: flex;
  justify-content: space-between;
  div {
    width: calc(50% - 10px) !important;
    position: relative;
    @mixin fake-labels($text) {
      content: $text;
      position: absolute;
      top: 0;
      left: 15px;
      font-size: 12px;
      color: var(--white-dark);
      padding: 0 5px;
      background-color: white;
      transform: translateY(-50%);
      transition: .3s
    }
    &:first-child::after {
      @include fake-labels('min')
    }
    &:last-child::after {
      @include fake-labels('max')
    }
    &:has(input:hover)::after, &:focus-within::after {
      color: var(--greenn);
    }
    input {
      width: 100%;
      margin-bottom: 0 !important;
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
  }
}
.b-tab-filters {
  padding: 15px 30px;
  border: 1px solid #ededf0 !important;
  border-radius: 10px;
  max-height: 470px;
  min-height: 470px;
  overflow: auto;
  scrollbar-gutter: stable;
  &::-webkit-scrollbar-thumb {
    background-color: #d4d8db;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 5px;
    background: transparent;
  }
}
.additional {
  display: flex;
  gap: 15px;
}
.add-additional {
  widows: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  div {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // width: 35px;
    // height: 35px;
    padding: 7px 13px;
    border-radius: 10px;
    margin-top: 20px;
    font-size: 12px;
    color: var(--greenn);
    border: 1px solid var(--greenn);
    transition: all 0.3s;
    &:hover {
      transform: scale(1.02);
    }
  }
}
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}
.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.top-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .row {
    flex-direction: column;
  }
  .top-filter {
    flex-direction: column;
    align-items: inherit;
    display: block;
  }
  .top-filter #limpar {
    margin-top: 15px;
  }
}
.form-relative {
  position: relative;
}
.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}

.btn-adicional {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-top: 30px;
  padding-right: 15px;
}

.card-validation {
  width: 100%;
  background: rgba(33, 51, 210, 0.1);
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 30px;
  display: flex;
}
.card-validation p {
  color: var(--bluee);
  font-size: 13px;
  line-height: 1.5;
}

.vue-daterange-picker {
  display: block !important;
}

.temp-index {
  z-index: 0;
}
.switch-tag {
  z-index: 0 !important;
  margin-top: 6px;
}

.switch-tag p {
  font-weight: 500;
  padding-top: 2px;
}

label {
  display: block !important;
  margin-bottom: 7px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  padding: 0 !important;
}
</style>
<style lang="scss">
#leads-filters {
  .multiselect__tag{
    padding: 7.5px 35px 7.5px 10px !important;
  }
  .multiselect__tags{
    max-height: 136px !important;
  }
  .multiselect__tag-icon{
    background-color: rgba(247,247,247, 0.2) !important;
    border-radius: 100% !important;
    width: 20px !important;
    height: 20px !important;
    font-weight: 400 !important; 
    top: 4px !important;
    right: 5px !important;
    justify-content: center !important;
    align-items: center !important;
    display: flex !important;
  }
  .multiselect__tag-icon:after{
    color:var(--white-light) !important;
    font-size: 25px !important;
  }
}
</style>
